import { useEffect, useRef } from 'react';

export function useValueRef<TValue>(value: TValue) {
    const ref = useRef(value);
    ref.current = value;
    return ref;
}

export function usePrevious<TValue>(value: TValue) {
    const ref = useRef<TValue | undefined>(undefined);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

type UpdateEffect<TValue> = (value: TValue, previousValue: TValue) => void;

export function useUpdateEffect<TValue>(value: TValue, effect: UpdateEffect<TValue>) {
    const effectRef = useValueRef(effect);
    const previousValueRef = useValueRef(usePrevious(value));
    useEffect(() => {
        if (previousValueRef.current === undefined) {
            return;
        }
        effectRef.current(value, previousValueRef.current);
    }, [previousValueRef, value, effectRef]);
}
