import React, { ReactNode, useContext, useState } from 'react';

interface ContextValue {
    setCollapseLocalStorageKey: (storageKey: string) => void;
    setIsCollapsed: (isCollapsed: boolean) => void;
    isCollapsed: boolean;
    setBreadcrumbs: (breadcrumbs: React.ReactNode[]) => void;
    breadcrumbs: React.ReactNode[];
}

const NavBarContext = React.createContext<ContextValue | null>(null);

interface ProviderProps {
    children: ReactNode;
}

export const NavBarProvider: React.FC<ProviderProps> = ({ children }) => {
    const [collapseStorageKey, setCollapseLocalStorageKey] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [breadcrumbs, setBreadcrumbs] = useState<React.ReactNode[]>([]);

    const loadCollapsePreference = (collapseStoragekey: string) => {
        setIsCollapsed(localStorage.getItem(collapseStoragekey) === 'true');
        setCollapseLocalStorageKey(collapseStoragekey);
    };

    const saveCollapsePreference = (isCollapsed: boolean) => {
        if (collapseStorageKey) {
            const value = isCollapsed ? 'true' : 'false';
            localStorage.setItem(collapseStorageKey, value);
        }
        setIsCollapsed(isCollapsed);
    };

    return (
        <NavBarContext.Provider
            value={{
                setCollapseLocalStorageKey: loadCollapsePreference,
                isCollapsed,
                setIsCollapsed: saveCollapsePreference,
                setBreadcrumbs,
                breadcrumbs,
            }}
        >
            {children}
        </NavBarContext.Provider>
    );
};

export function useNavBar() {
    const ctx = useContext(NavBarContext);
    if (!ctx) {
        throw new Error('Nav bar provider is not detected');
    }
    return ctx;
}
