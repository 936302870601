export const HELP_BASE_URL = 'https://support.getresq.com';
export const KUSTOMER_HELP_BASE_URL = 'https://getresq.kustomer.help';

export const INTERCOM_LINKS = {
    VENDOR_SECTION: `${HELP_BASE_URL}/en/collections/2681011-vendors`,
    SCHEDULING_A_JOB: `${HELP_BASE_URL}/SkZDY1oEh`,
    UPLOAD_VENDOR_INVOICE: `${HELP_BASE_URL}/en/articles/5577649-sending-invoices-to-resq`,
    BUDGETS: `${HELP_BASE_URL}/en/articles/7336688-create-and-manage-budgets`,
    VENDOR_SCORE: `${HELP_BASE_URL}/en/articles/7198634`,
    CUSTOM_REPORTING: `${HELP_BASE_URL}/en/articles/7925167`,
    FLEXIBLE_SPEND_APPROVALS: `${HELP_BASE_URL}/en/articles/7988679`,
    FAVOURITE_VENDORS: `${HELP_BASE_URL}/en/articles/8117892`,
};

export const HELP_LINKS = {
    VENDOR_SECTION: `${KUSTOMER_HELP_BASE_URL}/categories/Bk_IAiuO2`,
    SCHEDULING_A_JOB: `${KUSTOMER_HELP_BASE_URL}/SkZDY1oEh`,
    UPLOAD_VENDOR_INVOICE: `${KUSTOMER_HELP_BASE_URL}/rydLK1oNh`,
    BUDGETS: `${KUSTOMER_HELP_BASE_URL}/rJUHF1o4n`,
    VENDOR_SCORE: `${KUSTOMER_HELP_BASE_URL}/rJuHK1iVn`,
    CUSTOM_REPORTING: `${KUSTOMER_HELP_BASE_URL}/rJpr3EpB2`,
    FLEXIBLE_SPEND_APPROVALS: `${KUSTOMER_HELP_BASE_URL}/rkZBc8RUh`,
    FAVOURITE_VENDORS: `${KUSTOMER_HELP_BASE_URL}/HkxgVIPn`,
    PAYMENTS: `${KUSTOMER_HELP_BASE_URL}/SJvLtyoE3`,
    SENSORS: `${HELP_BASE_URL}/BkSQ9VYA`,
};
