import React, { useContext } from 'react';
import { AnalyticsContextProps } from './interfaces';

export interface AnalyticsContextProviderProps {
    value: AnalyticsContextProps;
}

const context = React.createContext<AnalyticsContextProviderProps | null>(null);

export const AnalyticsContextProvider: React.FC<AnalyticsContextProviderProps> = ({
    value,
    children,
}) => {
    const parentContextValue = useContext(context);
    const mergedValue = parentContextValue ? { ...parentContextValue.value, ...value } : value;
    return <context.Provider value={{ value: mergedValue }}>{children}</context.Provider>;
};

export function useAnalyticsContext() {
    const contextValue = useContext(context);
    if (!contextValue) {
        return { context: null };
    }
    const { value } = contextValue;
    return { context: value };
}
