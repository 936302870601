import { GraphQLError } from 'graphql';

export class ValidationError extends GraphQLError {
    readonly fields: { [key: string]: string[] };

    constructor(error: GraphQLError, message?: string) {
        super(!!message ? message : error.message, { ...error });
        this.fields = (error.extensions?.fields as { [key: string]: string[] } | null) ?? {};
    }
}

export class AuthorizationError extends GraphQLError {
    constructor(error: GraphQLError, message?: string) {
        super(!!message ? message : error.message, { ...error });
    }
}

export class InternalServerError extends GraphQLError {
    constructor(error: GraphQLError, message?: string) {
        super(!!message ? message : error.message, { ...error });
    }
}
