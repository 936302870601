// Define Omit type helper
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#predefined-conditional-types
// FIXME: Remove after upgrading to TypeScript 3.5, as it officially introduces

import { Maybe } from 'src/graphql/lib';

// Omit as a helper type supported by the language
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * This function should be used to guard default switch cases
 * that are not expected to be reached, it allows TypeScript
 * to exhaustively check whether all enum values are covered
 * by the switch, due to the use of `never` here.
 */
export function assertUnreachable(input: never): never {
    throw new Error(`Unstable state reached with value: ${input}`);
}

export function filterStringsToEnumValues<TEnum extends string>(
    strings: Array<string | null>,
    enumType: Record<any, TEnum>,
): TEnum[] {
    const enumValues = Object.values(enumType);
    return strings.filter((s) => s && enumValues.includes(s as any)) as TEnum[];
}

export function parseStringAsEnumValue<TEnum extends string>(
    string: string | null | undefined,
    enumType: Record<any, TEnum>,
) {
    if (string === null || string === undefined) {
        return null;
    }
    const enumValues = Object.values(enumType);
    if (enumValues.includes(string as any)) {
        return string as TEnum;
    }
    return null;
}

export function getEnumValues<TEnum extends string>(enumType: Record<any, TEnum>) {
    return Object.values(enumType);
}

type PaginatedNodeType<TKey extends string, TNode = any> = {
    [key in TKey]: Maybe<{
        edges: Maybe<Maybe<TNode>[]>;
    }>;
};

export type GetPaginatedNodeType<
    TKey extends string,
    TQueryNode extends PaginatedNodeType<TKey>,
> = NonNullable<NonNullable<NonNullable<TQueryNode[TKey]>['edges'][any]>['node']>;
