import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

export interface ConfirmLeaveContextProps {
    count: number;
    increaseCount(): void;
    decreaseCount(): void;
}
const noop = () => undefined;

const context = React.createContext<ConfirmLeaveContextProps>({
    count: 0,
    increaseCount: noop,
    decreaseCount: noop,
});

interface ProviderProps {
    children: ReactNode;
}

export function ConfirmLeaveProvider({ children }: ProviderProps) {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);
    const increaseCount = () => setCount(count + 1);
    const decreaseCount = () => setCount(count - 1);
    useEffect(() => {
        if (count > 0) {
            window.onbeforeunload = () =>
                t('client.editableDetails.discardChangesConfirmationMessage');
        } else {
            window.onbeforeunload = null;
        }
    }, [t, count]);
    return (
        <context.Provider value={{ count, increaseCount, decreaseCount }}>
            {children}
            <Prompt
                when={count > 0}
                message={t('client.editableDetails.discardChangesConfirmationMessage')}
            />
        </context.Provider>
    );
}

export const useConfirmLeave = (editing: boolean) => {
    const { increaseCount, decreaseCount } = useContext(context);
    const countedRef = useRef(false);
    useEffect(() => {
        if (editing && !countedRef.current) {
            increaseCount();
            countedRef.current = true;
        } else if (!editing && countedRef.current) {
            decreaseCount();
            countedRef.current = false;
        }
    }, [editing]); // eslint-disable-line react-hooks/exhaustive-deps
    // Make sure count is unset on unmount
    useEffect(
        () => () => {
            if (countedRef.current) {
                decreaseCount();
            }
        },
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );
};
