import React, { ReactNode, useContext, useState } from 'react';

interface ContextValue {
    setBanner: (node: ReactNode) => void;
    banner?: ReactNode;
}

const BannerContext = React.createContext<ContextValue | null>(null);

interface ProviderProps {
    children: ReactNode;
}

export const BannerProvider: React.FC<ProviderProps> = ({ children }) => {
    const [banner, setBanner] = useState<ReactNode>();
    return (
        <BannerContext.Provider value={{ banner, setBanner }}>{children}</BannerContext.Provider>
    );
};

export function useBanner() {
    const ctx = useContext(BannerContext);
    if (!ctx) {
        throw new Error('Banner provider is not detected');
    }
    return ctx;
}
