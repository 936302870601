import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'src/api/provider';
import { LoadingView } from 'src/lib/LoadingView';
import Container from './components/Container';

export const FacilityAppSSO: React.FC = () => {
    const { t } = useTranslation('auth.login');
    const { t: errorT } = useTranslation('errors');
    const apiClient = useAPI();
    const [loadingState, setLoadingState] = useState<'loading' | 'loaded' | 'error'>('loading');
    useEffect(() => {
        apiClient
            .post('/saml/app-redirect/', {})
            .then((response) => {
                // The app will run this within a webview and inject this property.
                // The app also does string matching on this message
                (window as any).ReactNativeWebView?.postMessage(
                    'TokenResponse: ' + JSON.stringify(response),
                );
                setLoadingState('loaded');
            })
            .catch((reason) => {
                setLoadingState('error');
            });
    });
    return (
        <Container width={360}>
            <Typography>{t('appRedirect')}</Typography>
            <Box sx={{ pt: 2 }}>
                <LoadingView
                    state={loadingState}
                    errorView={<Typography>{errorT('generic')}</Typography>}
                />
            </Box>
        </Container>
    );
};
