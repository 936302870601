import * as Sentry from '@sentry/browser';
import { useNullableSessionUser } from '../../sessionContext';

const SentryUserContext = () => {
    const session = useNullableSessionUser();
    const scope = Sentry.getCurrentScope();
    if (session) {
        scope.setUser({
            id: session.sourceId.toString(),
            name: `${session.firstName} ${session.lastName}`,
            username: session.username,
            email: session.email,
        });
    } else {
        scope.setUser(null);
    }
    return null;
};

export default SentryUserContext;
