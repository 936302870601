import { CSSProperties, useMemo } from 'react';

export type TypographyRole =
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'body'
    | 'body-small'
    | 'body-bold'
    | 'body-small-bold'
    | 'label'
    | 'label-light'
    | 'label-bold'
    | 'label-large'
    | 'label-large-light'
    | 'label-large-bold'
    | 'label-small'
    | 'label-small-bold'
    | 'label-smallest'
    | 'label-enclosed'
    | 'label-enclosed-bold'
    | 'label-enclosed-small-bold'
    | 'button'
    | 'button-small'
    | 'tooltip-title'
    | 'tooltip-body';

const typographyStyles: Record<TypographyRole, CSSProperties> = {
    heading1: { fontSize: 24, lineHeight: 1.5, fontWeight: '600' },
    heading2: { fontSize: 20, lineHeight: 1.5, fontWeight: '600' },
    heading3: { fontSize: 18, lineHeight: 1.35, fontWeight: '600' },
    heading4: { fontSize: 14, lineHeight: 1.45, fontWeight: '500' },
    body: { fontSize: 16, lineHeight: 1.5, fontWeight: '400' },
    'body-small': { fontSize: 14, lineHeight: 1.45, fontWeight: '400' },
    'body-bold': { fontSize: 16, lineHeight: 1.5, fontWeight: '600' },
    'body-small-bold': { fontSize: 14, lineHeight: 1.45, fontWeight: '500' },
    label: { fontSize: 14, lineHeight: 1.45, fontWeight: '500' },
    'label-light': { fontSize: 14, lineHeight: 1.45, fontWeight: '400' },
    'label-bold': { fontSize: 14, lineHeight: 1.45, fontWeight: '600' },
    'label-large': { fontSize: 16, lineHeight: 1.5, fontWeight: '500' },
    'label-large-light': { fontSize: 16, lineHeight: 1.5, fontWeight: '400' },
    'label-large-bold': { fontSize: 16, lineHeight: 1.5, fontWeight: '600' },
    'label-small': { fontSize: 13, lineHeight: 1.4, fontWeight: '400' },
    'label-small-bold': { fontSize: 13, lineHeight: 1.4, fontWeight: '600' },
    'label-smallest': { fontSize: 12, lineHeight: 1.2, fontWeight: '400' },
    'label-enclosed': { fontSize: 14, lineHeight: 1.25, fontWeight: '500' },
    'label-enclosed-bold': { fontSize: 14, lineHeight: 1.25, fontWeight: '600' },
    'label-enclosed-small-bold': { fontSize: 12, lineHeight: 1.25, fontWeight: '600' },
    button: { fontSize: 14, lineHeight: 1.25, fontWeight: '500' },
    'button-small': { fontSize: 12, lineHeight: 1.2, fontWeight: '500' },
    'tooltip-title': { fontSize: 12, lineHeight: 1.5, fontWeight: '600' },
    'tooltip-body': { fontSize: 12, lineHeight: 1.5, fontWeight: '500' },
};

export const useTypography = (role: TypographyRole) => {
    const { fontSize, lineHeight, fontWeight } = useMemo(() => typographyStyles[role], [role]);

    return {
        fontSize,
        lineHeight,
        fontWeight,
    };
};
