import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_HOST, CSRF_COOKIE_NAME } from 'src/constants/environment';
import { ANALYTICS_ANONYMOUS_ID } from 'src/constants/localStorage';
import { ACCESS_TOKEN_KEY, IMPERSONATED_KEY } from 'src/constants/sessionStorage';
import Cookies from 'universal-cookie';
import { Omit } from '../helpers/typescript';
import APIClient, { UploadedFile } from './APIClient';

const cookies = new Cookies();

export interface APIContextProps {
    apiClient: APIClient;
    signOut: (redirectTo?: string) => void;
}

const context = React.createContext<APIContextProps | null>(null);

export const withAPI = <P extends APIContextProps, R = Omit<P, keyof APIContextProps>>(
    Component: React.ComponentType<P>,
): React.FC<R> => {
    return (props: R) => {
        return (
            <context.Consumer>
                {(apiContext) => (
                    <Component {...(props as any)} apiClient={apiContext!.apiClient} />
                )}
            </context.Consumer>
        );
    };
};

export const Provider: React.FC = ({ children }) => {
    const apiClient = useMemo(() => new APIClient(API_HOST + '/api', CSRF_COOKIE_NAME), []);
    const signOut = useCallback(
        async (redirectTo?: string) => {
            try {
                await apiClient!.post('/auth/logout/');
            } finally {
                const rootDomain = document.domain.replace(/^\w+\./, '.');
                const options = {
                    path: '/',
                    domain: rootDomain,
                };
                cookies.remove(CSRF_COOKIE_NAME, options);

                sessionStorage.removeItem(IMPERSONATED_KEY);
                sessionStorage.removeItem(ACCESS_TOKEN_KEY);
                localStorage.removeItem(ANALYTICS_ANONYMOUS_ID);

                window.location.href = redirectTo ?? '/login';
            }
        },
        [apiClient],
    );

    return <context.Provider value={{ apiClient, signOut }}>{children}</context.Provider>;
};

function useValue() {
    const value = useContext(context);
    if (!value) {
        throw new Error('APIProvider is not detected.');
    }
    return value;
}

export function useAPI() {
    const { apiClient } = useValue();
    return apiClient;
}

export function useSignOut() {
    const { signOut } = useValue();
    return signOut;
}

export function useUploadFiles() {
    const { t } = useTranslation('validation');
    const { enqueueSnackbar } = useSnackbar();
    const api = useAPI();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const upload = useCallback(
        async (files: Array<File>) => {
            setLoading(true);
            setError(null);
            try {
                const uploadedFiles = await Promise.all(files.map((file) => api.uploadFile(file)));
                setUploadedFiles(uploadedFiles);
                return uploadedFiles;
            } catch (err) {
                const error = t('unknownErrorMessage');
                setError(error);
                enqueueSnackbar(error, { variant: 'error' });
                throw err;
            } finally {
                setLoading(false);
            }
        },
        [api, enqueueSnackbar, t],
    );
    const result = useMemo(
        () => ({
            loading,
            error,
            uploadedFiles,
        }),
        [loading, error, uploadedFiles],
    );
    return [upload, result] as const;
}
