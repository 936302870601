import {
    add,
    addMonths,
    getDate,
    getDay,
    parse,
    parseISO,
    setDay,
    setHours,
    setMinutes,
    startOfMinute,
    startOfWeek,
    toDate,
} from 'date-fns';
import { format as formatDateFn, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

// go-to formats should either be DATE or DATE_TIME_ZONED
export enum TimestampFormat {
    ISO_DATE = 'yyyy-MM-dd',
    DATE = 'LLL d, yyyy',
    DISPLAY_DATE = 'EEE • MMM d',
    MONTH_DAY = 'LLL d',
    MONTH_DAY_LONG = 'LLLL d',
    MONTH_DAY_YEAR = "LLL d ''yy",
    MONTH_DAY_YEAR_LONG = 'LLLL d yyyy',
    MONTH_YEAR = 'LLL yyyy',
    MONTH_YEAR_SHORT = "LLL ''yy",
    MONTH_YEAR_LONG = 'LLLL yyyy',
    TIME = "h:mmaaaaa'm'",
    TIME_ZONED = "h:mmaaaaa'm' z",
    TIME_DATE_ZONED = "haaaaa'm' z 'on' LLL d, yyyy",
    TIME_24_HOUR = 'HH:mm',
    HOUR = "haaaaa'm'",
    DATE_TIME = "LLL d, yyyy 'at' h:mmaaaaa'm'",
    DATE_TIME_ZONED = "LLL d, yyyy 'at' h:mmaaaaa'm' z",
    MONTH_DAY_TIME_ZONED = "LLL d 'at' h:mmaaaaa'm' z",
    ZONE = 'z',
    HOUR_ZONE_MONTH_DATE = 'h a z, LLL d',
    HOUR_ZONE = 'h:mm a z',
    TIME_ZONE_MONTH_DATE = 'h:mm a z, LLL d',
}

export interface FormatOptions {
    timeZone?: string;
    // TODO: locale
}

export const parseTimestamp = (ts: Date | string | number | null | undefined) => {
    if (!ts) {
        return null;
    }
    if (typeof ts === 'string') {
        return parseISO(ts);
    }
    return toDate(ts);
};

export const formatTimestamp = (
    ts: Date | string | number | null | undefined,
    format: TimestampFormat | string,
    options?: FormatOptions,
) => {
    let date = parseTimestamp(ts);
    if (!date) {
        return '';
    }
    if (options?.timeZone) {
        date = utcToZonedTime(date, options.timeZone);
    }
    return formatDateFn(date, format, options);
};

export const getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const toISODate = (ts: Date | string | number | null | undefined, timeZone?: string) => {
    return formatTimestamp(ts, TimestampFormat.ISO_DATE, { timeZone }) || null;
};

export const fromDateAndTime = (date: string, time: string, timeZone: string) => {
    const [hours, minutes] = time
        .toString()
        .split(':')
        .map((v: string) => parseInt(v, 10));
    return zonedTimeToUtc(
        startOfMinute(setMinutes(setHours(parse(date, 'yyyy-MM-dd', new Date()), hours), minutes)),
        timeZone,
    );
};

export const parseISODate = (date: string) => {
    return parse(date, TimestampFormat.ISO_DATE, new Date());
};

export const formatDateWindow = (
    from: Date | string | number | null | undefined,
    to: Date | string | number | null | undefined,
) => {
    from = parseTimestamp(from);
    to = parseTimestamp(to);

    if (!from || !to) {
        return '';
    }

    if (from.getTime() === to.getTime()) {
        return formatTimestamp(from, TimestampFormat.MONTH_DAY_YEAR);
    }

    return (
        formatTimestamp(
            from,
            from.getFullYear() === to.getFullYear()
                ? TimestampFormat.MONTH_DAY
                : TimestampFormat.MONTH_DAY_YEAR,
        ) +
        ' - ' +
        formatTimestamp(to, TimestampFormat.MONTH_DAY_YEAR)
    );
};

export const formatDateTimeWindow = ({
    ts1,
    ts2,
    options,
    noDate = false,
}: {
    ts1: Date | string | number | null | undefined;
    ts2: Date | string | number | null | undefined;
    options?: FormatOptions;
    noDate?: boolean;
}) => {
    ts1 = parseTimestamp(ts1);
    ts2 = parseTimestamp(ts2);
    if (!ts1 || !ts2) {
        return '';
    }

    return `${formatTimestamp(
        ts1,
        ts1.getDay() === ts2.getDay() ? 'h:mm' : 'h:mm a',
        options,
    )} - ${formatTimestamp(
        ts2,
        noDate ? TimestampFormat.HOUR_ZONE : TimestampFormat.HOUR_ZONE_MONTH_DATE,
        options,
    )}`;
};

export const getNextDayOfWeek = ({
    dayOfWeek,
    date = new Date(),
    includeToday = true,
}: {
    dayOfWeek: number;
    date?: Date;
    includeToday?: boolean;
}): Date => {
    // dayOfWeek: 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    // Calculate the start of the week considering Monday as the first day of the week
    let result = setDay(startOfWeek(date, { weekStartsOn: 1 }), dayOfWeek, { weekStartsOn: 1 });

    if (result < date || (!includeToday && result === date)) {
        // If the day is before the current date, or today but today should not be included, jump to the next week
        result = add(result, { weeks: 1 });
    }

    return result;
};

export const getNextDayOfMonth = ({
    dayOfMonth,
    date = new Date(),
    includeToday = true,
}: {
    dayOfMonth: number;
    date?: Date;
    includeToday?: boolean;
}): Date => {
    const currentDay = getDate(date);

    if (currentDay < dayOfMonth || (includeToday && currentDay === dayOfMonth)) {
        // If today's date is less than the specific day, or it's the same day and includeToday is true, set this month's date to that day
        return new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
    } else {
        // If today's date is past the specific day, or it's the same day but includeToday is false, set next month's date to that day
        return addMonths(new Date(date.getFullYear(), date.getMonth(), dayOfMonth), 1);
    }
};

export const convertToSeconds = (hours: number | null) => {
    if (!hours) {
        return hours;
    }

    return Math.max(0, hours * 3600);
};

export const convertToHours = (seconds: number | null) => {
    if (!seconds) {
        return seconds;
    }

    return Math.max(0, seconds / 3600);
};

export const getZonedDate = (ts: string | null, tz: string): number | null => {
    if (!ts) {
        return null;
    }
    return getDate(utcToZonedTime(ts, tz));
};

export const getZonedWeekday = (ts: string | null, tz: string): number | null => {
    if (!ts) {
        return null;
    }
    return getDay(utcToZonedTime(ts, tz));
};

export const getZonedIsoDate = (ts: string | null, tz: string): string | null => {
    if (!ts) {
        return null;
    }
    return toISODate(utcToZonedTime(ts, tz));
};
