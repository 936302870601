import { AnalyticsBrowser } from '@segment/analytics-next';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RouteProps, matchPath, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ANALYTICS_SESSION_ID } from 'src/constants/localStorage';
import { getIntegerId } from 'src/helpers/getIntegerId';
import { useQueryParam } from 'src/helpers/queryParam';
import { useValueRef } from 'src/helpers/react';
import { useFeatureFlags } from 'src/routes/featureFlagsContext';
import { useSession } from 'src/routes/sessionContext';
import { useAnalyticsContext } from './context';
import { AnalyticsAccountType, AnalyticsEvent } from './events';
import { INIT_CONTEXT_PROPS, addGroupContext, formatAnalyticsDict } from './helpers';
import { AnalyticsPageProps, AnalyticsTrackProps } from './interfaces';
import {
    SegmentGroupPayload,
    SegmentIdentifyPayload,
    SegmentPagePayload,
    SegmentPageProperties,
    SegmentPayload,
    SegmentTrackPayload,
    SegmentTrackProperties,
} from './segment';
interface Value {
    page: (properties: SegmentPageProperties) => void;
    track: (event: AnalyticsEvent, properties: SegmentTrackProperties) => void;
    disablePageTracking: (route: RouteProps) => void;
}

const context = React.createContext<Value | null>(null);

export interface AnalyticsProviderProps {
    segmentWriteKey: string;
    accountType?: AnalyticsAccountType;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
    segmentWriteKey,
    children,
    accountType,
}) => {
    const analytics = useMemo(
        () =>
            AnalyticsBrowser.load(
                {
                    writeKey: segmentWriteKey,
                },
                {
                    storage: {
                        stores: ['cookie', 'memory'],
                    },
                },
            ),
        [segmentWriteKey],
    );
    const [anonymousId] = useQueryParam('anonymousId');
    const { featureFlags } = useFeatureFlags();
    const featureFlagsRef = useValueRef(featureFlags);
    const { me, realMe } = useSession();
    const { pathname, search } = useLocation();
    const [previousLocation, setPreviousLocation] = useState<string | null>(null);

    useEffect(() => {
        setPreviousLocation(pathname);
    }, [pathname, previousLocation, setPreviousLocation]);

    const buildBasePayload = useCallback(() => {
        const payload: SegmentPayload = {
            options: {
                integrations: {
                    'Actions Amplitude': {
                        session_id: localStorage.getItem(ANALYTICS_SESSION_ID),
                    },
                },
            },
        };
        if (realMe && realMe.profile && !realMe.profile.isAnonymous) {
            payload.userId = getIntegerId(realMe.id).toString();
        }
        return payload;
    }, [realMe]);

    useEffect(() => {
        const payload: SegmentIdentifyPayload = {
            ...buildBasePayload(),
        };
        if (realMe) {
            let company = undefined;
            if (realMe.clientMemberships.length == 1) {
                company = {
                    id: realMe.clientMemberships[0].client.trackingId.toString(),
                    name: realMe.clientMemberships[0].client.name,
                };
            }
            if (realMe.vendorMemberships.length == 1) {
                company = {
                    id: realMe.vendorMemberships[0].vendor.trackingId.toString(),
                    name: realMe.vendorMemberships[0].vendor.name,
                };
            }
            payload.traits = {
                account_type: accountType,
                company: company,
            };

            if (!realMe.profile.isAnonymous) {
                payload.traits = {
                    ...payload.traits,
                    ...{
                        name: `${realMe.firstName} ${realMe.lastName}`,
                        firstName: realMe.firstName,
                        lastName: realMe.lastName,
                        email: realMe.email,
                        username: realMe.username,
                        phone: realMe.profile?.phone ?? undefined,
                    },
                };
            }
        }

        analytics.identify(payload.userId, payload.traits, {
            ...payload.options,
            anonymousId: anonymousId ?? undefined,
        });
    }, [buildBasePayload, realMe, analytics, accountType, anonymousId]);

    useEffect(() => {
        const clients = realMe?.clientMemberships.map((membership) => membership.client) ?? [];
        for (const client of clients) {
            const payload: SegmentGroupPayload = {
                ...buildBasePayload(),
                groupId: getIntegerId(client.id).toString(),
                traits: {
                    name: client.name,
                    type: 'client',
                },
            };
            analytics.group(payload.groupId, payload.traits, payload.options);
        }

        const vendors = realMe?.vendorMemberships.map((membership) => membership.vendor) ?? [];
        for (const vendor of vendors) {
            const payload: SegmentGroupPayload = {
                ...buildBasePayload(),
                groupId: getIntegerId(vendor.id).toString(),
                traits: {
                    name: vendor.name,
                    type: 'vendor',
                },
            };
            analytics.group(payload.groupId, payload.traits, payload.options);
        }
    }, [buildBasePayload, realMe, analytics]);

    const page = useCallback(
        (properties: SegmentPageProperties) => {
            const payload: SegmentPagePayload = {
                ...buildBasePayload(),
                properties: {
                    path: pathname,
                    previous_location: previousLocation,
                    referrer: document.referrer,
                    search,
                    title: document.title,
                    url: location.href,
                    ...properties,
                },
            };
            const options = addGroupContext(payload);
            analytics.page(payload.category, payload.name, payload.properties, options);
        },
        [buildBasePayload, search, analytics, previousLocation, pathname],
    );

    const track = useCallback(
        (event: AnalyticsEvent, properties: SegmentTrackProperties) => {
            const payload: SegmentTrackPayload = {
                ...buildBasePayload(),
                event,
                properties: { path: pathname, ...properties },
            };
            const options = addGroupContext(payload);
            analytics.track(payload.event, payload.properties, options);
        },

        [buildBasePayload, analytics, pathname],
    );

    const routesWithDisabledPageTracking = useMemo<RouteProps[]>(() => [], []);
    const disablePageTracking = useCallback(
        (route: RouteProps) => {
            if (
                routesWithDisabledPageTracking.some(
                    (existingRoute) =>
                        existingRoute.path === route.path && existingRoute.exact === route.exact,
                )
            ) {
                return;
            }
            routesWithDisabledPageTracking.push(route);
        },
        [routesWithDisabledPageTracking],
    );
    const pageRef = useValueRef(page);
    const routesWithDisabledPageTrackingRef = useValueRef(routesWithDisabledPageTracking);
    useEffect(() => {
        for (const route of Array.from(routesWithDisabledPageTrackingRef.current)) {
            if (matchPath(pathname, route)) {
                return;
            }
        }
        pageRef.current({
            path: pathname,
            referrer: document.referrer,
            search,
            title: document.title,
            url: location.href,
            featureFlags: featureFlagsRef.current.join(','),
        });
    }, [routesWithDisabledPageTrackingRef, pageRef, pathname, search, featureFlagsRef]);

    return (
        <context.Provider
            value={{
                page,
                track,
                disablePageTracking,
            }}
        >
            {children}
        </context.Provider>
    );
};

function useValue() {
    const value = useContext(context);
    if (!value) {
        throw new Error('AnalyticsProvider is not detected.');
    }
    return value;
}

export function usePage() {
    const { context } = useAnalyticsContext();
    const { featureFlags } = useFeatureFlags();
    const { page: rawPage, disablePageTracking } = useValue();

    const match = useRouteMatch();
    useEffect(() => {
        disablePageTracking({
            path: match.path,
            exact: match.isExact,
            strict: false,
        });
    }, [disablePageTracking, match]);

    const contextRef = useValueRef(context);
    const featureFlagsRef = useValueRef(featureFlags);
    const page = useCallback(
        (properties: AnalyticsPageProps) => {
            rawPage(
                formatAnalyticsDict({
                    ...INIT_CONTEXT_PROPS,
                    ...contextRef.current,
                    ...properties,
                    feature_flags: featureFlagsRef.current.join(','),
                }),
            );
        },
        [rawPage, contextRef, featureFlagsRef],
    );

    return page;
}

export function useTrack() {
    const { context } = useAnalyticsContext();
    const { featureFlags } = useFeatureFlags();
    const { track: rawTrack } = useValue();
    const generateTrackingId = useGenerateTrackingId();
    const contextRef = useValueRef(context);
    const featureFlagsRef = useValueRef(featureFlags);
    const generateTrackingIdRef = useValueRef(generateTrackingId);
    const track = useCallback(
        (event: AnalyticsEvent, properties: AnalyticsTrackProps) => {
            const { pageName } = (contextRef.current as Record<string, any>) ?? {};
            const { trackingId, label, elementType } = properties ?? {};

            const analyticsTrackingId =
                trackingId ??
                generateTrackingIdRef.current(
                    elementType?.toString(),
                    pageName?.toString(),
                    label?.toString(),
                );

            rawTrack(
                event,
                formatAnalyticsDict({
                    ...INIT_CONTEXT_PROPS,
                    ...contextRef.current,
                    ...properties,
                    tracking_id: analyticsTrackingId,
                    feature_flags: featureFlagsRef.current.join(','),
                }),
            );
        },
        [rawTrack, contextRef, featureFlagsRef, generateTrackingIdRef],
    );
    return track;
}

function useGenerateTrackingId() {
    const { pathname } = useLocation();
    const pathNameRef = useValueRef(pathname);
    const urlParams = useParams();

    const generateTrackingIdCallback = useCallback(
        (elementType?: string | null, pageName?: string | null, label?: string | null) => {
            return generateTrackingId(pathNameRef.current, urlParams, elementType, pageName, label);
        },
        [pathNameRef, urlParams],
    );
    return generateTrackingIdCallback;
}

export function generateTrackingId(
    pathName: string,
    urlParams: Record<string, any>,
    elementType?: string | null,
    pageName?: string | null,
    label?: string | null,
) {
    // replacing global id's in the URL with their keys
    const splitPath = pathName.split('/');
    for (const [key, value] of Object.entries(urlParams)) {
        const trimmedKey = key.slice(-2) == 'Id' ? key.slice(0, -2) : key;
        const param = value as string;
        const i = splitPath.indexOf(param);
        if (i != -1) {
            splitPath[i] = trimmedKey;
        }
    }
    const pathString = splitPath.join('_').replace(/^_*/, '');
    return `auto_${pageName ?? pathString}_${label ?? 'unlabeled'}_${elementType ?? 'element'}`
        .toLowerCase()
        .replace(/\d{4,}/g, (match) => `${'#'.repeat(match.length)}`) // filter out codes
        .replace(/[-\s]+/g, '_'); // replace whitespace and dashes
}

// uses the same context for storybook, cannot be easily moved to another file
export const MockAnalyticsProvider: React.FC = ({ children }) => {
    const value = {
        page: () => null,
        track: () => null,
        disablePageTracking: () => null,
    };
    return <context.Provider value={value}>{children}</context.Provider>;
};
