import { FormikConfig, FormikContextType, FormikState, FormikValues, useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';

export interface FormContext<T> extends FormikContextType<T> {
    validationSchemaYup: any;
    resetFormRef: React.MutableRefObject<(nextState?: Partial<FormikState<T>> | undefined) => void>;
}

export default function useForm<Values extends FormikValues = FormikValues>(
    formConfig: FormikConfig<Values>,
): FormContext<Values> {
    const submitCountRef = React.useRef(0);
    const formik = useFormik<Values>({
        validateOnMount: false,
        validateOnChange: submitCountRef.current > 0,
        validateOnBlur: submitCountRef.current > 0,
        ...formConfig,
    });
    submitCountRef.current = formik.submitCount;

    const resetFormRef = React.useRef(formik.resetForm);
    resetFormRef.current = formik.resetForm;
    //validation schema is not properly exposed by formik, this is a work around
    return {
        ...formik,
        resetFormRef: resetFormRef,
        validationSchemaYup: formConfig.validationSchema,
    };
}

export const useScrollToError = (isSubmitting: boolean) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!isSubmitting && containerRef.current) {
            const errorElement = containerRef.current.querySelector('.Mui-error');
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [isSubmitting]);

    return { containerRef };
};
