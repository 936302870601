import { useLocation, useParams } from 'react-router-dom';
import { IMPERSONATED_KEY } from '../constants/sessionStorage';

interface RouteParams {
    userId: string;
}

const Impersonate: React.FC = () => {
    const { search } = useLocation();
    const { userId } = useParams<RouteParams>();
    sessionStorage.setItem(IMPERSONATED_KEY, userId);

    const urlParams = new URLSearchParams(search);
    const next = urlParams.get('next') ?? '/';

    window.location.href = next;
    return null;
};

export default Impersonate;
