import { Container, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KUSTOMER_HELP } from 'src/constants/featureFlags';
import { HELP_LINKS, INTERCOM_LINKS } from 'src/constants/helpLinks';
import { QuestionCircleIcon } from 'src/icons';
import { useFeatureFlags } from '../featureFlagsContext';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
    helpTextWrap: {
        display: 'flex',
        alignItems: 'center',
    },
    helpLink: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: theme.spacing(2),
    },
    helpIcon: {
        marginRight: theme.spacing(2),
        fontSize: 18.75,
    },
    helpText: {
        position: 'relative',
        top: 1,
    },
}));

interface Props {
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const VendorHelpLink: React.FC<Props> = ({ size }) => {
    const { t } = useTranslation('vendor.index');
    const classes = useStyles();
    const { featureFlags } = useFeatureFlags();
    return (
        <Container maxWidth={size} className={classes.root}>
            <a
                href={
                    featureFlags.includes(KUSTOMER_HELP)
                        ? HELP_LINKS.VENDOR_SECTION
                        : INTERCOM_LINKS.VENDOR_SECTION
                }
                target="_blank"
                rel="noopener noreferrer"
                className={classes.helpLink}
            >
                <Typography variant="h5" className={classes.helpTextWrap}>
                    <SvgIcon component={QuestionCircleIcon} className={classes.helpIcon} />
                    <span className={classes.helpText}>{t('helpText')}</span>
                </Typography>
            </a>
        </Container>
    );
};

export default VendorHelpLink;
