import jwt_decode from 'jwt-decode';
import { StringParam, useQueryParam } from 'use-query-params';
import { ACCESS_TOKEN_KEY } from '../constants/sessionStorage';

export const useAccessToken = () => {
    const [queryToken, setQueryToken] = useQueryParam('accessToken', StringParam);
    const savedToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    const token = savedToken || queryToken;
    if (!token) {
        return null;
    }

    let decoded: any = null;
    try {
        decoded = jwt_decode(token);
    } catch (err) {
        console.error(`Invalid JWT token '${token}'`, err);
    }

    if (queryToken) {
        setQueryToken(null);
    }
    if (!decoded?.user_id) {
        return null;
    }
    if (!savedToken) {
        sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    return decoded;
};
