function getEnv(key: string): string | undefined {
    return (window as any).ENV[key];
}

function requireEnv(key: string): string {
    const value = getEnv(key);
    if (value === undefined) {
        throw new Error(`Required environment variable is not defined: ${key}`);
    }
    return value;
}

export const API_HOST = requireEnv('API_HOST');
export const CSRF_COOKIE_NAME = requireEnv('CSRF_COOKIE_NAME');
export const STRIPE_PUBLISHABLE_KEY = requireEnv('STRIPE_PUBLISHABLE_KEY');
export const STRIPE_PUBLISHABLE_KEY_CA = getEnv('STRIPE_PUBLISHABLE_KEY_CA');
export const STRIPE_PUBLISHABLE_KEY_US = getEnv('STRIPE_PUBLISHABLE_KEY_US');
export const STRIPE_PUBLISHABLE_KEY_UK = getEnv('STRIPE_PUBLISHABLE_KEY_UK');
export const DEFAULT_SEGMENT_WRITE_KEY = requireEnv('DEFAULT_SEGMENT_WRITE_KEY');
export const OPS_SEGMENT_WRITE_KEY = requireEnv('OPS_SEGMENT_WRITE_KEY');
export const ORC_SEGMENT_WRITE_KEY = requireEnv('ORC_SEGMENT_WRITE_KEY');
export const PMC_SEGMENT_WRITE_KEY = requireEnv('PMC_SEGMENT_WRITE_KEY');
export const RMC_SEGMENT_WRITE_KEY = requireEnv('RMC_SEGMENT_WRITE_KEY');
export const MAPTILER_API_KEY = getEnv('MAPTILER_API_KEY') || '';
export const ENVIRONMENT = requireEnv('ENVIRONMENT');
export const IS_LIVE_ENVIRONMENT = ['production', 'staging'].indexOf(ENVIRONMENT) >= 0;
export const RELEASE_VERSION = getEnv('RELEASE_VERSION');
export const SENTRY_DSN = getEnv('SENTRY_DSN');
export const ENTITY_EXPORT_LIMIT = +getEnv('ENTITY_EXPORT_LIMIT')!;
export const MAPBOX_PUBLISHABLE_KEY = getEnv('MAPBOX_PUBLISHABLE_KEY');
// Hard-coding the Kustomer API key for now. This key is used
// for tracking and is not sensitive security-wise.
export const KUSTOMER_API_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0NzRkYTE0NTZiNDA0NmI1NmVhMDM0MCIsInVzZXIiOiI2NDc0ZGExNGQwN2JmN2E0OGY5ZDBkOGMiLCJvcmciOiI2NDAyNTE2N2FmNGI4MzMzNmEwYjg3NTUiLCJvcmdOYW1lIjoiZ2V0cmVzcSIsInVzZXJUeXBlIjoibWFjaGluZSIsInBvZCI6InByb2QxIiwicm9sZXMiOlsib3JnLnRyYWNraW5nIl0sImF1ZCI6InVybjpjb25zdW1lciIsImlzcyI6InVybjphcGkiLCJzdWIiOiI2NDc0ZGExNGQwN2JmN2E0OGY5ZDBkOGMifQ.OsbYV0VNrz4Xdbf0mLD0Cf-W3OZKddVKXGkHBv6rccI';
