import { Box, Paper, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import APP_STORE_ICON from '../assets/appstore.svg';
import GOOGLE_PLAY_ICON from '../assets/googleplay.svg';
import LOGO from '../assets/logo.svg';
import { ANDROID_CLIENT_APP_LINK, APPLE_CLIENT_APP_LINK } from '../constants/mobile-app-links';

const Logo = styled('img')`
    height: 48px;
`;

const AppStoreImage = styled('img')`
    width: 100%;
`;

const QRCodeLanding: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation('independent.qrCodeLanding');
    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={1}
        >
            <Box textAlign="center">
                <Logo src={LOGO} />
            </Box>
            <Box marginTop={4} width="100%" maxWidth={420} paddingBottom="80px">
                <Paper>
                    <Box padding={5} textAlign="center">
                        <Box fontSize={32} fontWeight={600} lineHeight={1.2}>
                            {t('heading')}
                        </Box>
                        <Box marginTop={2} fontSize={18} lineHeight={1.4}>
                            {t('subtext')}
                        </Box>
                        <Box
                            marginTop={3}
                            display="grid"
                            gap={theme.spacing(2)}
                            gridTemplateColumns="120fr 135fr"
                        >
                            <a
                                href={APPLE_CLIENT_APP_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AppStoreImage src={APP_STORE_ICON} />
                            </a>
                            <a
                                href={ANDROID_CLIENT_APP_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <AppStoreImage src={GOOGLE_PLAY_ICON} />
                            </a>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
};

export default QRCodeLanding;
