import * as Apollo from '@apollo/client';
import { useAnalyticsContext } from 'src/lib/analytics/context';
// reexport the whole module, then override specific functions
export * from '@apollo/client';

export function useQuery<
    TData = any,
    TVariables extends Apollo.OperationVariables = Apollo.OperationVariables,
>(
    query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
    options?: Apollo.QueryHookOptions<TData, TVariables>,
): Apollo.QueryResult<TData, TVariables> {
    const context = useAnalyticsContext();
    const annotatedOptions = {
        ...options,
        context: {
            ...options?.context,
            headers: {
                ...options?.context?.headers,
                ['X-ProductWorkflow']: context?.context?.productWorkflow?.toString() || undefined,
                ['X-Webpage']: context?.context?.pageName?.toString() || undefined,
            },
        },
    };
    return Apollo.useQuery<TData, TVariables>(query, annotatedOptions);
}

export function useLazyQuery<
    TData = any,
    TVariables extends Apollo.OperationVariables = Apollo.OperationVariables,
>(
    query: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
    options?: Apollo.LazyQueryHookOptions<TData, TVariables>,
): Apollo.LazyQueryResultTuple<TData, TVariables> {
    const context = useAnalyticsContext();
    const annotatedOptions = {
        ...options,
        context: {
            ...options?.context,
            headers: {
                ...options?.context?.headers,
                ['X-ProductWorkflow']: context?.context?.productWorkflow?.toString() || undefined,
                ['X-Webpage']: context?.context?.pageName?.toString() || undefined,
            },
        },
    };
    return Apollo.useLazyQuery<TData, TVariables>(query, annotatedOptions);
}

export function useMutation<
    TData = any,
    TVariables = Apollo.OperationVariables,
    TContext = Apollo.DefaultContext,
    TCache extends Apollo.ApolloCache<any> = Apollo.ApolloCache<any>,
>(
    mutation: Apollo.DocumentNode | Apollo.TypedDocumentNode<TData, TVariables>,
    options?: Apollo.MutationHookOptions<TData, TVariables, TContext, TCache>,
): Apollo.MutationTuple<TData, TVariables, TContext, TCache> {
    const context = useAnalyticsContext();
    const newOptions = {
        ...options,
        context: {
            ...options?.context,
            headers: {
                ...(options?.context as any)?.headers,
                ['X-ProductWorkflow']: context?.context?.productWorkflow?.toString() || undefined,
                ['X-Webpage']: context?.context?.pageName?.toString() || undefined,
            },
        },
    };
    return Apollo.useMutation<TData, TVariables, TContext, TCache>(
        mutation,
        newOptions as unknown as Apollo.MutationHookOptions<TData, TVariables, TContext, TCache>,
    );
}
