export enum AnalyticsEventType {
    ANALYTICS = 'ANALYTICS',
}

export enum AnalyticsEventSource {
    FRONTEND = 'FRONTEND',
}

export enum AnalyticsEvent {
    ExperimentViewed = 'Experiment Viewed',
    ElementDisplayed = 'Element Displayed',
    ElementClicked = 'Element Clicked',
    FileDownloaded = 'File Downloaded',
    ElementUnfocused = 'Element Unfocused',
}

export enum ClickElementType {
    BUTTON = 'BUTTON',
    ACCORDION = 'ACCORDION',
    LINK = 'LINK',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN_OPTION = 'DROPDOWN_OPTION',
    RADIO_BUTTON = 'RADIO_BUTTON',
    SECTION = 'SECTION',
    TEXT_INPUT = 'TEXT_INPUT',
    SWITCH = 'SWITCH',
    DATE_PICKER = 'DATE_PICKER',
}

export enum DisplayElementType {
    MODAL = 'MODAL',
    POPUP = 'POPUP',
    ALERT = 'ALERT',
    CARD = 'CARD',
    BANNER = 'BANNER',
    FORM_ERROR = 'FORM_ERROR',
}

export type AnalyticsElementType = ClickElementType | DisplayElementType;

export enum AnalyticsSource {
    RMC = 'RMC',
    RCC = 'RCC',
    PMC = 'PMC',
    LOGIN = 'LOGIN',
    APPLESS_SCHEDULING = 'APPLESS_SCHEDULING',
    OFF_RESQ_CUSTOMER_PORTAL = 'OFF_RESQ_CUSTOMER_PORTAL',
    INDEPENDENT = 'INDEPENDENT',
}

export enum AnalyticsAccountType {
    RMC = 'facility',
    RCC = 'resq_user',
    PMC = 'vendor',
    INDEPENDENT = 'independent',
}

export enum AnalyticsOrganizationType {
    Vendor = 'VENDOR',
    Client = 'CLIENT',
}

export enum AnalyticsProductWorkflow {
    DailyMenu = 'daily_menu',
    WorkOrderCreation = 'work_order_creation',
    FastPayComms = 'fast_pay_comms',
    NoteStatusUpdate = 'note_status_update',
    WorkOrderNoteInquiryToVendor = 'work_order_note_inquiry_to_vendor',
    VendorDailyMenu = 'vendor_daily_menu',
    Login = 'login',
    FavouriteVendor = 'favourite_vendor',
    ClientOnboarding = 'client_owner_onboarding',
    InheritedVendorOnboarding = 'inherited_vendor_onboarding',
    MarketplaceVendorOnboarding = 'marketplace_vendor_onboarding',
    InheritedToMarketplaceVendorOnboarding = 'inherited_to_marketplace_vendor_onboarding',
    DispatchedVendorOnboarding = 'dispatched_vendor_onboarding',
}

export enum AnalyticsFileType {
    PDF = 'PDF',
    CSV = 'CSV',
    JPG = 'JPG',
    UNKNOWN = 'UNKNOWN',
}
