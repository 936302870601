import React, { useContext, useMemo } from 'react';
import { useGetFeatureFlagsQuery } from 'src/graphql/lib';
import { useNullableSessionUser } from './sessionContext';

interface Value {
    organizationId?: string;
    facilityId?: string;
}

interface Props {
    facilityId?: string;
    organizationId?: string;
}

const context = React.createContext<Value | null>(null);

export const FeatureFlagsProvider: React.FC<Props> = ({ facilityId, organizationId, children }) => {
    const parentContextValue = useContext(context);

    const mergedValue = useMemo(
        () => ({
            organizationId: organizationId ?? parentContextValue?.organizationId,
            facilityId,
        }),
        [parentContextValue, facilityId, organizationId],
    );

    return <context.Provider value={mergedValue}>{children}</context.Provider>;
};

interface FeatureFlagsParams {
    facilityId?: string | null;
    organizationId?: string | null;
}

export const useFeatureFlags = ({ facilityId, organizationId }: FeatureFlagsParams = {}) => {
    const me = useNullableSessionUser();
    const value = useContext(context);

    const { data, refetch, loading } = useGetFeatureFlagsQuery({
        variables: {
            facilityId: facilityId ?? value?.facilityId ?? undefined,
            organizationId: organizationId ?? value?.organizationId ?? undefined,
        },
        skip: !me,
        fetchPolicy: 'cache-first',
    });

    const featureFlags = useMemo(() => {
        return data?.featureFlags ?? [];
    }, [data]);

    return { featureFlags, refetch, loading };
};
