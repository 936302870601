/**
 * Gets the value of a cookie by name.
 * @param name - Name of cookie.
 */
const getCookie = (name: string): string | null => {
    if (!document.cookie) {
        return null;
    }
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (let n = 0, nn = cookies.length; n < nn; n++) {
        const cookie = cookies[n];
        if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
        }
    }
    return null;
};

export default getCookie;
