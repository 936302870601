import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'src/lib/components/Button';
import Container from '../components/Container';

interface Props {
    onRequestMagicLink: (c: boolean) => void;
}

export const MagicLinkSent: React.FC<Props> = ({ onRequestMagicLink: setRequestingMagicLink }) => {
    const { t } = useTranslation('auth.magicLink');
    return (
        <Container width={360}>
            <Typography variant="h2" textAlign="center" marginBottom={1}>
                {t('loginLinkSent')}
            </Typography>
            <Typography variant="body1" textAlign="center" marginBottom={4}>
                {t('loginLinkResult')}
            </Typography>
            <Typography variant="body1" textAlign="center" marginBottom={4}>
                {t('checkSpamHelp')}
            </Typography>
            <Box marginBottom={4}>
                <Divider />
            </Box>
            <Box marginBottom={2}>
                <Button
                    kind="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setRequestingMagicLink(true)}
                >
                    {t('resendLoginLink')}
                </Button>
            </Box>

            <Box>
                <RouterLink to="/login">
                    <Button kind="filled" color="secondary" fullWidth>
                        {t('returnToLogin')}
                    </Button>
                </RouterLink>
            </Box>
        </Container>
    );
};
