import { Tooltip, TooltipProps } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import palette from 'src/constants/palette';

export interface StyledTooltipProps extends TooltipProps {}

export const StyledTooltip = withStyles({
    tooltip: {
        backgroundColor: palette.tooltip, // TODO: embed in theme
        '& .MuiTooltip-arrow': {
            color: palette.tooltip,
        },
    },
})(Tooltip);

export default StyledTooltip;
