import { History, createBrowserHistory } from 'history';
import React, { useContext } from 'react';

// Important note: If you don't specify a ResQHistoryProvider in the component hierarchy,
// it'll default to a BrowserHistory based on this invocation.
const context = React.createContext<History | null>(createBrowserHistory());

interface ResQHistoryProviderProps {
    history: History;
    children: React.ReactNode;
}

export const ResQHistoryProvider: React.FC<ResQHistoryProviderProps> = ({ children, history }) => {
    return <context.Provider value={history}>{children}</context.Provider>;
};

export function useResQHistory() {
    const value = useContext(context);
    if (!value) {
        throw new Error(
            "This shouldn't be possible: ResQHistoryProvider should default to createBrowserHistory",
        );
    }
    return value;
}
