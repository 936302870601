import { isDate } from 'date-fns';
import i18n, { Resource, ResourceKey } from 'i18next';
import LanguageDector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { TimestampFormat, formatTimestamp } from './helpers/datetime';

declare const require: {
    context: (directory: string, useSubdirectories: boolean, regExp: RegExp) => any;
};

function getI18NextResources() {
    // It may be better to refactor this into an i18next backend plugin
    const modules = import.meta.glob<ResourceKey>('./translations/**/*.json', {
        eager: true,
        query: '?raw',
        import: 'default',
    });
    const resources: Resource = {};
    for (const key in modules) {
        const segments = key
            .replace(/^\.\//, '')
            .split('/')
            .map((s) => s.replace(/\.json$/, ''));
        const language = segments[1];
        resources[language] = resources[language] || {};
        const namespace = segments.length === 2 ? 'translation' : segments.slice(2).join('.');
        resources[language][namespace] = JSON.parse(modules[key] as string);
    }
    return resources;
}

export default function () {
    // i18n is being added here only as a proof-of-concept.
    // TODO:
    // 1. Figure out a way to namespace the keys (e.g. "facilities.details.facilityDetails.provinceLabel").
    // 2. Add en-US translations where appropriate.
    // 3. Figure out pluralization support and find a place on the site to use it on.
    i18n.use(LanguageDector)
        .use(initReactI18next)
        .init({
            resources: getI18NextResources(),
            fallbackLng: 'en-CA',
            interpolation: {
                escapeValue: false,
                format: (value, format) => {
                    if (isDate(value)) {
                        const idx = Object.keys(TimestampFormat).indexOf(format || '');
                        const f = Object.values(TimestampFormat)[idx] ?? TimestampFormat.DATE;
                        return formatTimestamp(value, f);
                    }
                    return value;
                },
            },
            nsSeparator: false,
        });
}
