import qs from 'query-string';

export const STRING_TRUE = '1';
export const STRING_FALSE = '0';

/*
This module wraps functions in query-string to provide consistent handling of
array-type parameters in the URL.

In order to get consistent serialization and deserialization of query string
array type parameters (e.g. '?facilityIds=1,2,3`), we must consistently use
the `arrayFormat: 'bracket'` option in query-string.

By default, if you serialize an array with 1 element (e.g. faciliityIds = [1]),
query-string will encode as if it was a singular value, which erases the array
type information, and leads to confusion when that parameter is parsed later on.
*/

const getOptionsWithArrayFormat = (options: qs.StringifyOptions): qs.StringifyOptions => {
    return {
        ...options,
        arrayFormat: 'bracket',
    };
};

export const qsStringify = (
    object: { [key: string]: unknown },
    options: qs.StringifyOptions = {},
) => {
    return qs.stringify(object, getOptionsWithArrayFormat(options));
};

export const qsParse = (url: string, options: qs.ParseOptions = {}) => {
    return qs.parse(url, getOptionsWithArrayFormat(options));
};

export const qsStringifyUrl = (object: qs.ParsedUrl, options: qs.ParseOptions = {}) => {
    return qs.stringifyUrl(object, getOptionsWithArrayFormat(options));
};

export const qsStringifyBoolean = (value: boolean | null) => {
    return value === null ? null : value ? STRING_TRUE : STRING_FALSE;
};
