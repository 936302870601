import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ForbiddenError, UnauthorizedError } from 'src/api/APIClient';
import { useAPI } from 'src/api/provider';
import { REFERRER_STORAGE_KEY } from 'src/constants/sessionStorage';
import { Button } from 'src/lib/components/Button';
import { Link } from 'src/lib/components/Link';
import { TextField } from 'src/lib/components/fields/TextField';
import * as Yup from 'yup';
import Container from '../components/Container';
import { MagicLinkSent } from './MagicLinkSent';

interface FormValues {
    username: string;
}
interface MagicLinkFormProps {
    path: string;
    onRequestMagicLink(c: boolean): void;
    initialUsername: string;
}

export const MagicLinkForm: React.FC<MagicLinkFormProps> = ({
    path,
    onRequestMagicLink,
    initialUsername,
}) => {
    const { t } = useTranslation('auth.magicLink');
    const { t: tValidation } = useTranslation('validation');

    const api = useAPI();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Formik<FormValues>
            validationSchema={Yup.object().shape({
                username: Yup.string().required(tValidation('required')),
            })}
            initialValues={{
                username: initialUsername,
            }}
            onSubmit={async (values, { setErrors, setSubmitting }) => {
                try {
                    await api.post('/magic-link-send/', {
                        path,
                        username: values.username,
                    });
                    onRequestMagicLink(false);
                } catch (err: any) {
                    if (err instanceof UnauthorizedError || err instanceof ForbiddenError) {
                        setErrors({ username: err.message });
                    } else {
                        enqueueSnackbar(err.message, { variant: 'error' });
                    }
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ values, isSubmitting, submitCount, errors, handleSubmit, setFieldValue }) => {
                if (submitCount === 0) {
                    errors = {};
                }
                return (
                    <form onSubmit={handleSubmit}>
                        <Box marginBottom={4}>
                            <TextField
                                label={t('usernameLabel')}
                                name="username"
                                autoComplete="username"
                                value={values.username}
                                onChange={(event) => {
                                    const username = event.target.value;
                                    setFieldValue('username', username);
                                }}
                                error={errors.username}
                                autoFocus
                            />
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                type="submit"
                                kind="filled"
                                color="primary"
                                fullWidth
                                disabled={isSubmitting}
                            >
                                {t('sendLoginLinkButton')}
                            </Button>
                        </Box>
                    </form>
                );
            }}
        </Formik>
    );
};

export const MagicLink: React.FC = () => {
    const { t } = useTranslation('auth.magicLink');

    const location = useLocation<any>();
    const referrer = location.state?.referrer ?? null;
    const storedReferrerLink = sessionStorage.getItem(REFERRER_STORAGE_KEY);
    const referrerLink = (referrer && referrer.pathname + referrer.search) || storedReferrerLink;

    const [requestMagicLink, setRequestMagicLink] = useState<boolean>(true);

    if (!requestMagicLink) {
        return <MagicLinkSent onRequestMagicLink={setRequestMagicLink} />;
    }

    return (
        <Container width={360}>
            <Typography variant="h2" textAlign="center" marginBottom={2}>
                {t('loginLinkTitle')}
            </Typography>
            <Typography variant="body1" textAlign="center" marginBottom={4}>
                {t('loginLinkInfo')}
            </Typography>
            <MagicLinkForm
                path={referrerLink || ''}
                onRequestMagicLink={setRequestMagicLink}
                initialUsername=""
            />
            <Box marginBottom={2}>
                <RouterLink to="/login">
                    <Button kind="filled" color="secondary" fullWidth>
                        {t('returnToLogin')}
                    </Button>
                </RouterLink>
            </Box>
            <Typography variant="body3" textAlign="center">
                <Link to="/sso">{t('loginWithSSO')}</Link>
            </Typography>
        </Container>
    );
};
