import i18n from 'i18next';
import React, { useContext, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useNullableSessionUser } from './sessionContext';

interface Value {
    locale: string;
}

export const context = React.createContext<Value | null>(null);

export const LocaleProvider: React.FunctionComponent = ({ children }) => {
    const me = useNullableSessionUser();
    const locale = me?.profile?.locale ?? 'en-CA';
    useEffect(() => {
        if (i18n.isInitialized) {
            if (
                i18n.language != locale &&
                i18n.options.resources &&
                Object.keys(i18n.options.resources).includes(locale)
            ) {
                i18n.changeLanguage(locale);
            }
        }
    }, [locale]);

    return (
        <I18nextProvider i18n={i18n}>
            <context.Provider value={{ locale }}>{children}</context.Provider>
        </I18nextProvider>
    );
};

function useValue() {
    const value = useContext(context);
    if (!value) {
        throw new Error('LocaleProvider is not detected.');
    }
    return value;
}

export function useLocale() {
    const { locale } = useValue();
    return locale;
}
