import { Card, Divider, Grid, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import APP_STORE_APPLE from 'src/assets/appstore.svg';
import APP_STORE_ANDROID from 'src/assets/googleplay.svg';
import ICON_APP_STORE_VENDOR from 'src/assets/iconAppStoreVendor.svg';
import QR_APPLE from 'src/assets/qrVendorAppStore.png';
import QR_ANDROID from 'src/assets/qrVendorGooglePlay.png';
import { ANDROID_VENDOR_APP_LINK, APPLE_VENDOR_APP_LINK } from 'src/constants/mobile-app-links';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    icon: {
        margin: theme.spacing(2, 0),
    },
    title: {
        fontSize: 18,
    },
    description: {
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },
    qrWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    qr: {
        width: '100%',
    },
    instructions: {
        margin: theme.spacing(1, 1, 2, 1),
    },
    storeLink: {
        margin: theme.spacing(1),
    },
    divider: {
        width: 100,
        margin: theme.spacing(4),
    },
}));

const DownloadVendorAppCard: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation('vendor.index');
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const AppleButton: React.FC = () => (
        <Link href={APPLE_VENDOR_APP_LINK}>
            <img className={classes.storeLink} src={APP_STORE_APPLE} alt={t('appleLinkAlt')} />
        </Link>
    );
    const GoogleButton: React.FC = () => (
        <Link href={ANDROID_VENDOR_APP_LINK}>
            <img className={classes.storeLink} src={APP_STORE_ANDROID} alt={t('androidLinkAlt')} />
        </Link>
    );
    return (
        <Card className={classes.root}>
            <img
                src={ICON_APP_STORE_VENDOR}
                alt={t('downloadApp.appLogoAlt')}
                className={classes.icon}
            />
            <Typography variant="h2" className={classes.title}>
                {t('downloadApp.sectionTitle')}
            </Typography>
            <Typography variant="body2" className={classes.description}>
                {t('downloadApp.appDescription')}
            </Typography>
            {isMobile ? (
                <div>
                    <AppleButton />
                    <GoogleButton />
                </div>
            ) : (
                <>
                    <Grid container>
                        <Grid item xs={4} className={classes.qrWrap}>
                            <img className={classes.qr} src={QR_APPLE} alt={t('qrAppleAlt')} />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" className={classes.instructions}>
                                {t('downloadApp.appleDownloadInstructions')}
                            </Typography>
                            <AppleButton />
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container>
                        <Grid item xs={4} className={classes.qrWrap}>
                            <img className={classes.qr} src={QR_ANDROID} alt={t('qrAndroidAlt')} />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" className={classes.instructions}>
                                {t('downloadApp.androidDownloadInstructions')}
                            </Typography>
                            <GoogleButton />
                        </Grid>
                    </Grid>
                </>
            )}
        </Card>
    );
};

export default DownloadVendorAppCard;
