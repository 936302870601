import React from 'react';

interface ComponentModule {
    default: React.ComponentType<any>;
}

type LazyImport = () => Promise<ComponentModule>;

export function componentLoader(lazyComponent: LazyImport, attemptsLeft = 5) {
    return new Promise<ComponentModule>((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error: unknown) => {
                // let us retry after 1500 ms
                setTimeout(() => {
                    if (attemptsLeft <= 0) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 1500);
            });
    });
}

export function lazyComponentLoader(lazyComponent: LazyImport) {
    return React.lazy(() => componentLoader(lazyComponent));
}
