import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { useTranslation } from 'react-i18next';
import { useSignOut } from 'src/api/provider';
import { Maybe } from 'src/graphql/lib';
import Cookies from 'universal-cookie';
import 'whatwg-fetch';
import { AuthorizationError, InternalServerError, ValidationError } from '../api/graphqlErrors';
import { API_HOST, CSRF_COOKIE_NAME } from '../constants/environment';
import { ACCESS_TOKEN_KEY, IMPERSONATED_KEY } from '../constants/sessionStorage';
import { FieldErrors } from './errorHandling';
const cookies = new Cookies();

function useApolloClient(
    networkStatusNotifierLink: ReturnType<typeof createNetworkStatusNotifier>['link'],
) {
    const { t } = useTranslation('validation');
    const { enqueueSnackbar } = useSnackbar();
    const signOut = useSignOut();

    const showError = useCallback(
        (message: string | string[]) => {
            if (Array.isArray(message)) {
                message = message.join(' ');
            }
            enqueueSnackbar(message, { variant: 'error' });
        },
        [enqueueSnackbar],
    );

    const apolloClient = useMemo(() => {
        const cache = new InMemoryCache({
            possibleTypes: {},
        });

        const httpLink = createHttpLink({
            uri: `${API_HOST}/api/graphql/`,
            credentials: 'include',
            fetch: window.fetch,
        });

        const customHeadersLink = setContext((_, { headers }) => {
            const customHeaders: { [key: string]: string } = {
                'X-CSRFToken': cookies.get(CSRF_COOKIE_NAME),
            };
            const impersonated = sessionStorage.getItem(IMPERSONATED_KEY);
            if (impersonated) {
                customHeaders['X-Impersonate'] = impersonated;
            }
            const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
            if (accessToken) {
                customHeaders['Authorization'] = `Bearer ${accessToken}`;
            }
            return {
                headers: {
                    ...headers,
                    ...customHeaders,
                },
                fetchOptions: {
                    referrerPolicy: 'no-referrer-when-downgrade',
                },
            };
        });

        const sentryBreadcrumbLink = new ApolloLink((operation, forward) => {
            Sentry.addBreadcrumb({
                category: 'gql',
                level: 'info',
                message: `Operation: ${operation.operationName}`,
            });

            return forward?.(operation) ?? null;
        });

        const errorLink = onError(
            ({ response, graphQLErrors, networkError, operation }: ErrorResponse) => {
                if (networkError) {
                    if (!('statusCode' in networkError)) {
                        showError(t('unknownErrorMessage'));
                        return;
                    }

                    if ([401, 403].includes(networkError.statusCode)) {
                        if (operation.operationName === 'GetMe') {
                            return;
                        }
                        if (operation.operationName === 'GetFeatureFlags') {
                            return;
                        }
                        if (window.location.pathname === '/login') {
                            return;
                        }
                        const redirectTo = sessionStorage.getItem(ACCESS_TOKEN_KEY)
                            ? '/invalid-link'
                            : undefined;
                        signOut(redirectTo);
                        return;
                    }

                    if (networkError.statusCode >= 400 && networkError.statusCode < 500) {
                        Sentry.captureException(networkError);
                    }

                    showError(t('unknownErrorMessage'));
                    return;
                }

                if (response && graphQLErrors) {
                    const errors: GraphQLError[] = [];
                    graphQLErrors.forEach((graphQLError) => {
                        if (!graphQLError.extensions) {
                            showError(graphQLError.message);
                            errors.push(new InternalServerError(graphQLError));
                            return;
                        }
                        switch (graphQLError.extensions.code) {
                            case 'ValidationError':
                                const errorFields = graphQLError.extensions.fields as FieldErrors;
                                if ('__all__' in errorFields) {
                                    showError(errorFields.__all__);
                                    errors.push(
                                        new ValidationError(graphQLError, errorFields.__all__),
                                    );
                                } else {
                                    const errorMessage = Object.values(errorFields).flatMap(error => error);
                                    showError(errorMessage || t('validationErrorMessage'));
                                    errors.push(
                                        new ValidationError(
                                            graphQLError,
                                            t('validationErrorMessage'),
                                        ),
                                    );
                                }
                                break;
                            case 'AuthorizationError':
                                errors.push(
                                    new AuthorizationError(
                                        graphQLError,
                                        t('unauthorizedErrorMessage'),
                                    ),
                                );
                                showError(t('unauthorizedErrorMessage'));
                                break;
                            case 'InternalServerError':
                            default:
                                errors.push(new InternalServerError(graphQLError));
                                showError(graphQLError.message);
                        }
                    });
                    response.errors = Object.freeze(errors);
                }
            },
        );

        return new ApolloClient({
            cache,
            link: ApolloLink.from([
                networkStatusNotifierLink,
                errorLink,
                customHeadersLink,
                sentryBreadcrumbLink,
                httpLink,
            ]),
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'all',
                    fetchPolicy: 'cache-and-network',
                    nextFetchPolicy: (lastFetchPolicy) => {
                        if (
                            lastFetchPolicy === 'cache-and-network' ||
                            lastFetchPolicy === 'network-only'
                        ) {
                            return 'cache-first';
                        }
                        return lastFetchPolicy;
                    },
                },
            },
        });
    }, [showError, t, networkStatusNotifierLink, signOut]);

    return apolloClient;
}

export interface GraphqlProviderProps {
    networkStatusNotifierLink: ReturnType<typeof createNetworkStatusNotifier>['link'];
}

export const GraphqlProvider: React.FC<GraphqlProviderProps> = ({
    networkStatusNotifierLink,
    children,
}) => {
    const client = useApolloClient(networkStatusNotifierLink);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

type Node = {
    id: string;
};

type Edge<TNode extends Node> = {
    node: Maybe<TNode>;
};

type Connection<TNode extends Node> = {
    edges: Maybe<Edge<TNode>>[];
};

export function getNodesFromConnection<TNode extends Node>(
    connection: Connection<TNode> | null | undefined,
) {
    if (!connection) {
        return null;
    }
    return connection.edges.map((edge) => edge?.node).filter(Boolean) as TNode[];
}
