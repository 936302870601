import {
    StyledEngineProvider,
    Theme,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider as OldThemeProvider } from '@mui/styles';
import React from 'react';
import vendorStyled, {
    ThemedStyledInterface,
    ThemeProvider as VendorThemeProvider,
} from 'styled-components';

// Exposes the injected properties allows us to
// prevent fullstory from injecting invalid props to theme providers
// which prints out an error
interface WireProps {
    children: (props: unknown) => React.ReactElement;
}

function Wire({ children, ...props }: WireProps) {
    return children(props);
}

interface ThemeProviderProps {
    theme: Theme;
    children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ theme, children }) => (
    <StyledEngineProvider injectFirst>
        <Wire>
            {(props) => (
                <VendorThemeProvider theme={theme}>
                    <OldThemeProvider theme={theme}>
                        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
                    </OldThemeProvider>
                </VendorThemeProvider>
            )}
        </Wire>
    </StyledEngineProvider>
);

export const styled: ThemedStyledInterface<Theme> = vendorStyled;
