import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VENDOR_LOGO from 'src/assets/logoVendor.svg';
import palette from 'src/constants/palette';
import DownloadVendorAppCard from './components/DownloadVendorAppCard';
import VendorHelpLink from './components/VendorHelpLink';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: palette.vendorBackdrop,
        width: '100%',
        height: '100%',
        padding: theme.spacing(6, 0),
        minHeight: '100vh',
    },
    logoWrap: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: theme.spacing(2),
    },
    messageWrap: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6, 0, 4),
    },
}));

const VendorLinkInvalid: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation('vendor.index');
    return (
        <div className={classes.root}>
            <VendorHelpLink size="xs" />
            <Container maxWidth="xs">
                <div className={classes.logoWrap}>
                    <img src={VENDOR_LOGO} alt={t('logoAlt')} />
                </div>
                <div className={classes.messageWrap}>
                    <Typography variant="h2">{t('invalidLink')}</Typography>
                </div>
                <DownloadVendorAppCard />
            </Container>
        </div>
    );
};

export default VendorLinkInvalid;
