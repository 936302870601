import palette from 'src/constants/palette';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
    0% {
        left: -100%;
    }
    49% {
        left: 100%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: -100%;
    }
`;

const Loader = styled('div')`
    width: 100%;
    position: fixed;
    z-index: 2000;
    height: 2px;
    background-color: ${palette.brand};
    animation-name: ${loading};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;

export default Loader;
