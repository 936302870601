import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from 'src/assets/logo.svg?react';

export const ClientLogoWithText = () => {
    const { t } = useTranslation('lib.components.logoWithText');

    return (
        <Stack mb={4} display="flex" alignItems="center">
            <Logo width="110px" />
            <Typography fontWeight={'700'} fontSize={'14px'}>
                {t('subtitleText')}
            </Typography>
        </Stack>
    );
};
