import { JSONValue } from '@segment/analytics-next';
import { snakeCase } from 'lodash';
import { getIntegerId, isBase64 } from 'src/helpers/getIntegerId';
import { SegmentPagePayload, SegmentTrackPayload, SegmentTrackProperties } from './segment';

export const addGroupContext = (payload: SegmentTrackPayload | SegmentPagePayload) => {
    let groups: JSONValue = {};
    const properties = payload.properties;
    const clientId = properties?.client_id;
    const vendorId = properties?.vendor_id;
    const facilityId = properties?.facility_id;
    const workOrderId = properties?.work_order_id;

    if (clientId) {
        groups = {
            ...groups,
            client: typeof clientId === 'string' ? getIntegerId(clientId) : clientId,
        };
    }
    if (vendorId) {
        groups = {
            ...groups,
            vendor: typeof vendorId === 'string' ? getIntegerId(vendorId) : vendorId,
        };
    }
    if (facilityId) {
        groups = {
            ...groups,
            facility: typeof facilityId === 'string' ? getIntegerId(facilityId) : facilityId,
        };
    }
    if (workOrderId) {
        groups = {
            ...groups,
            work_order: typeof workOrderId === 'string' ? getIntegerId(workOrderId) : workOrderId,
        };
    }
    const options = {
        ...payload.options,
        integrations: { ...payload.options?.integrations, Amplitude: { groups: groups } },
    };

    return options;
};

export const dictToSnakeCase = (dict: Record<string, any>) => {
    const newDict: Record<string, any> = {};
    for (const [key, value] of Object.entries(dict)) {
        newDict[snakeCase(key)] = value;
    }
    return newDict;
};

export const decodeBase64Dict = (dict: Record<string, any>) => {
    const newDict: Record<string, any> = {};
    for (const [key, value] of Object.entries(dict)) {
        try {
            if (typeof value === 'string' && isBase64(value)) {
                const intId = getIntegerId(value);
                if (!isNaN(intId)) {
                    newDict[key] = intId;
                    continue;
                }
            }
            newDict[key] = value;
        } catch {
            newDict[key] = value;
        }
    }
    return newDict;
};

export const formatAnalyticsDict = (dict: Record<string, any>) => {
    let newDict = dictToSnakeCase(dict);
    newDict = decodeBase64Dict(newDict);
    return newDict;
};

export const INIT_CONTEXT_PROPS: SegmentTrackProperties = {
    userId: null,
    attributedUserId: null,
    clientId: null,
    vendorId: null,
    facilityId: null,
    serviceCategory: null,
    equipmentId: null,
    maintenancePlanId: null,
    workOrderId: null,
    productWorkflow: null,
};
