import { useEffect } from 'react';
import { useSignOut } from 'src/api/provider';

export const SignOut: React.FC = () => {
    const signOut = useSignOut();
    useEffect(() => {
        signOut();
    }, [signOut]);
    return null;
};
