export const IMPERSONATED_KEY = 'impersonated';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFERRER_STORAGE_KEY = 'login-referrer';
export const PENDING_VISITS_REVIEWED = 'PENDING_VISITS_REVIEWED';
export const CAPEX_BANNER = 'capex-banner';
export const DAILY_MENU_OPENED_CARDS = 'daily_menu_opened_cards';
export const TEST_KEY = 'test_key';
export const OVERDUE_MODAL_STORAGE_KEY = 'overdueInvoicesModalStorageKey';
export const SHOW_INHERITED_VENDOR_WELCOME_MODAL = 'showInheritedVendorWelcomeModal';
export const HIDE_VERIFIED_VENDOR_SELECTED_DIALOG = 'hideVerifiedVendorSelectedDialog';
export const WORK_ORDER_SUBMITTED_USING_VERIFIED = 'workOrderSubmittedUsingVerified';
export const REQUEST_AUTOPAY_SETTINGS = 'requestAutopaySettings';
export const AUTOBOTS_VENDOR_CREATE_JOB_FEATURE_HIGHLIGHT = 'vendorCreateJobFeatureHighlight';
