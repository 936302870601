import { Box, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { UnauthorizedError } from 'src/api/APIClient';
import { useAPI } from 'src/api/provider';
import { API_HOST } from 'src/constants/environment';
import { Button } from 'src/lib/components/Button';
import { Link as FormattedLink } from 'src/lib/components/Link';
import { TextField } from 'src/lib/components/fields/TextField';
import Container from 'src/routes/Auth/components/Container';
import * as Yup from 'yup';
import { ContactFooter } from '../components/Footer';

interface FormValues {
    username: string;
}

export const SingleSignOn: React.FC = () => {
    const { t } = useTranslation('auth.sso');
    const { t: tValidation } = useTranslation('validation');

    const api = useAPI();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Container width={360}>
                <Formik<FormValues>
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required(tValidation('required')),
                    })}
                    initialValues={{
                        username: '',
                    }}
                    onSubmit={async (values, { setErrors, setSubmitting }) => {
                        try {
                            // check if username matches a saml based sso user
                            const login_result = await api.post('/saml/login/', {
                                username: values.username,
                            });
                            if (login_result['token']) {
                                window.location.href = `${API_HOST}/api/sso/sp/?token=${login_result['token']}`;
                            }
                        } catch (err: any) {
                            if (err instanceof UnauthorizedError) {
                                setErrors({ username: err.message });
                            } else {
                                enqueueSnackbar(err.message, { variant: 'error' });
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        values,
                        isSubmitting,
                        submitCount,
                        errors,
                        handleChange,
                        handleSubmit,
                    }) => {
                        if (submitCount === 0) {
                            errors = {};
                        }
                        return (
                            <form onSubmit={handleSubmit}>
                                <Box mb={4}>
                                    <TextField
                                        label={t('usernameLabel')}
                                        name="username"
                                        autoCapitalize="none"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        spellCheck={false}
                                        value={values.username}
                                        onChange={handleChange}
                                        error={errors.username}
                                        autoFocus
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Button
                                        type="submit"
                                        kind="filled"
                                        color="primary"
                                        fullWidth
                                        disabled={isSubmitting}
                                    >
                                        {t('continueWithSSO')}
                                    </Button>
                                </Box>
                                <Typography variant="body3" textAlign="center">
                                    <FormattedLink to="/login">
                                        {t('loginWithPassword')}
                                    </FormattedLink>
                                </Typography>
                            </form>
                        );
                    }}
                </Formik>
            </Container>
            <ContactFooter />
        </>
    );
};
