import { Box, Divider, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ForbiddenError, UnauthorizedError } from 'src/api/APIClient';
import { useAPI } from 'src/api/provider';
import { Button } from 'src/lib/components/Button';
import { Link } from 'src/lib/components/Link';
import { TextField } from 'src/lib/components/fields/TextField';
import * as Yup from 'yup';
import Container from '../Auth/components/Container';

interface FormValues {
    username: string;
}

export const ForgotPassword: React.FC = () => {
    const api = useAPI();
    const { t } = useTranslation('auth.forgotPassword');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [username, setUsername] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    if (isSubmitted) {
        return (
            <Container width={360}>
                <Typography variant="h2" textAlign="center" marginBottom={1}>
                    {t('resetInstructionsLabel')}
                </Typography>
                <Typography variant="body1" textAlign="center" marginBottom={4}>
                    {t('passwordResetEmailLabel', { username })}
                </Typography>
                <Typography variant="body1" textAlign="center" marginBottom={4}>
                    {t('additionalInfo')}
                </Typography>
                <Divider sx={{ marginBottom: 4 }} />
                <Box marginBottom={2}>
                    <Button
                        kind="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => setIsSubmitted(false)}
                    >
                        {t('resendInstructionsLabel')}
                    </Button>
                </Box>
                <Box>
                    <RouterLink to="/login">
                        <Button kind="filled" color="secondary" fullWidth>
                            {t('returnToLoginLabel')}
                        </Button>
                    </RouterLink>
                </Box>
            </Container>
        );
    }
    return (
        <Container width={360}>
            <Typography variant="h2" align="center" marginBottom={1}>
                {t('forgotPasswordTitle')}
            </Typography>
            <Typography variant="body1" align="center" marginBottom={4}>
                {t('forgotPasswordInfo')}
            </Typography>
            <Formik<FormValues>
                validationSchema={Yup.object().shape({
                    username: Yup.string().required('Required'),
                })}
                initialValues={{
                    username: '',
                }}
                onSubmit={async (values, { setErrors, setSubmitting }) => {
                    try {
                        await api.post('/client/request-password-reset/', {
                            username: values.username,
                        });
                        setUsername(values.username);
                        setIsSubmitted(true);
                    } catch (err: any) {
                        if (err instanceof UnauthorizedError || err instanceof ForbiddenError) {
                            setErrors({ username: err.message });
                        } else {
                            enqueueSnackbar(err.message, { variant: 'error' });
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, isSubmitting, submitCount, errors, handleChange, handleSubmit }) => {
                    if (submitCount === 0) {
                        errors = {};
                    }
                    return (
                        <form onSubmit={handleSubmit}>
                            <Box marginBottom={4}>
                                <TextField
                                    label={t('usernameLabel')}
                                    name="username"
                                    value={values.username}
                                    onChange={handleChange}
                                    error={errors.username}
                                    autoFocus
                                />
                            </Box>
                            <Box marginBottom={2}>
                                <Button
                                    type="submit"
                                    kind="filled"
                                    color="primary"
                                    fullWidth
                                    disabled={isSubmitting}
                                >
                                    {t('sendInstructionsLabel')}
                                </Button>
                            </Box>
                            <Box marginBottom={2}>
                                <RouterLink to="/login">
                                    <Button kind="filled" color="secondary" fullWidth>
                                        {t('returnToLoginLabel')}
                                    </Button>
                                </RouterLink>
                            </Box>
                            <Typography variant="body3" textAlign="center">
                                <Link to="/sso">{t('loginWithSSO')}</Link>
                            </Typography>
                        </form>
                    );
                }}
            </Formik>
        </Container>
    );
};
