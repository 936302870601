const brandOrange = 'hsla(18, 100%, 50%, 1)';
const primaryBlue = 'hsla(214, 100%, 41%, 1)';
const primaryPurple = 'hsla(253, 73%, 61%, 1)';
const primaryTeal = 'hsla(181, 100%, 38%, 1)';
const secondaryGreen = 'hsla(153, 52%, 47%, 1)';
const secondaryRed = 'hsla(357, 79%, 55%, 1)';
const secondaryYellow = 'hsla(43, 89%, 66%, 1)';
const secondaryLightBlue = 'hsla(205, 100%, 51%, 1)';
const secondaryGrey = 'hsla(220, 8%, 85%, 1)';
const resqBlue = 'hsla(200, 100%, 14%, 1)';

export enum Color {
    Black = '#17191C',
    White = '#FFFFFF',

    Grey50 = '#F7F7F7',
    Grey100 = '#EFEFF0',
    Grey200 = '#D6D8DC',
    Grey300 = '#ACB2B9',
    Grey400 = '#838B95',
    Grey500 = '#5E656E',
    Grey55 = '#8c8c8c',

    Red50 = '#FDEDEE',
    Red100 = '#FFCDD4',
    Red200 = '#EF999D',
    Red400 = '#E7323B',
    Red500 = '#D52835',
    Red600 = '#B91022',

    Orange100 = '#FDE9E6',
    Orange200 = '#FFAA8B',
    Orange300 = '#FF6D35',
    Orange400 = '#FD4C00',
    Orange500 = '#E13C00',
    Orange600 = '#C92C00',

    Yellow100 = '#FDF6E2',
    Yellow200 = '#FBE7B5',
    Yellow300 = '#F5CA5B',
    Yellow400 = '#EFA629',
    Yellow500 = '#EC8524',
    Yellow600 = '#A26201',

    Green50 = '#E3F5ED',
    Green100 = '#E4F4EC',
    Green200 = '#92D3B2',
    Green400 = '#39B67D',
    Green500 = '#009A5D',
    Green600 = '#007745',

    Teal50 = '#DBF6F6',
    Teal100 = '#8EE3E3',
    Teal200 = '#00BEC0',
    Teal300 = '#00A1A3',
    Teal400 = '#008586',
    Teal500 = '#00696B',
    Teal600 = '#004F50',

    Blue100 = '#E1F1FF',
    Blue200 = '#B8DBFF',
    Blue300 = '#53AFFF',
    Blue400 = '#007FF8',
    Blue500 = '#005BD1',
    Blue600 = '#0E3AB2',

    Purple50 = '#F2EFFC',
    Purple100 = '#D8CFF7',
    Purple200 = '#B1A0F0',
    Purple300 = '#9780EB',
    Purple400 = '#7D60E6',
    Purple500 = '#6248C5',
    Purple600 = '#4A3694',
}

export default {
    brand: '#FD4C00',
    primaryText: 'rgba(0, 0, 0, 0.85)',
    secondaryText: 'rgba(0, 0, 0, 0.45)',
    secondaryTitleBackground: '#F2F2F2',
    errorMessage: '#FF0A00',
    navBarBackground: '#23293C',
    shadow: 'hsla(0, 0%, 85%, 0.15)',
    inactive: 'rgba(139,139,139, 1)',
    active: '#2F80ED',
    brandOrange,
    primaryBlue,
    primaryPurple,
    primaryTeal,
    tealDark: '#00A1A1',
    secondaryGreen,
    secondaryRed,
    secondaryYellow,
    secondaryLightBlue,
    secondaryGrey,
    resqBlue,

    primary: brandOrange,
    textPrimary: 'hsla(214, 8%, 10%, 1)',
    textSecondary: 'hsla(214, 8%, 40%, 1)',
    textPlaceholder: 'hsla(214, 8%, 70%, 1)',
    textLight: 'hsla(0, 0%, 100%, 1)',
    border: 'hsla(214, 8%, 85%, 1)',
    background: 'hsla(0, 0%, 96%, 1)',
    highlight: 'hsla(0, 0%, 98%, 1)',
    tooltip: 'hsla(214, 8%, 10%, 0.9)',
    link: primaryBlue,
    neutral: '#E0E0E0', // mui 4's default
    error: '#f44336',
    payment: secondaryGreen,
    success: secondaryGreen,
    danger: secondaryRed,
    warn: secondaryYellow,
    warningMain: Color.Yellow400,
    warningLight: Color.Yellow100,
    vendorBackdrop: '#EDF1F7',
    vendorBackgroundBlue: '#DEF4F5',
    vendorBackgroundGrey: '#F7F7F7',
    vendorHighlightBlue: '#00BFC2',
    vendorHighlightDarkBlue: '#00736F',
    warningHiglightBrown: '#A26201',
    borderInteractive: '#0058CA',
    surfaceInteractiveHovered: 'rgb(0, 88, 202, .1)', // #0058CA with 10% opacity
};
