import { FieldHookConfig, useField } from 'formik';
import React from 'react';
import { useFormMetaData } from 'src/lib/components/form/FormProvider';

type ChangeEvent = React.ChangeEvent<any>;

export function ConsumeFormikContext<TFieldType, TBaseProps>(
    props: TBaseProps & FieldHookConfig<TFieldType>,
    Component: React.ComponentType<TBaseProps>,
) {
    const [field, meta, helpers] = useField(props as FieldHookConfig<TFieldType>);
    const formMetadata = useFormMetaData();
    const handleChange = (e: ChangeEvent | TFieldType) => {
        // support onChange that takes either an Event or the Processed value
        const has_target = (e as ChangeEvent)?.target !== undefined;
        if (has_target) {
            field.onChange(e as ChangeEvent);
        } else {
            helpers.setValue(e as TFieldType);
        }
    };

    const apiStatus = formMetadata?.apolloErrors?.[props.name];
    let error = meta.touched && meta.error;
    if (apiStatus && apiStatus?.error && field.value === apiStatus?.value) {
        error = apiStatus?.error;
    }
    const required =
        props?.required !== undefined
            ? props.required
            : formMetadata?.requiredFields?.[field.name]?.required;
    // todo we could check if editable is a prop on the component and if it's in formContext?.status, then editableDetails could toggle
    // editable via the context
    return (
        <Component
            {...field}
            touched={meta.touched.toString()}
            error={error}
            {...props}
            required={required}
            onChange={handleChange}
        />
    );
}

export default function withFormikConsumer<TFieldType, TBaseProps extends object>(
    Component: React.ComponentType<TBaseProps>,
): React.ComponentType<
    | TBaseProps
    | (Omit<TBaseProps, 'value' | 'onChange'> &
          Omit<FieldHookConfig<TFieldType>, 'value' | 'onChange'>)
> {
    return (
        props:
            | TBaseProps
            | (Omit<TBaseProps, 'value' | 'onChange'> &
                  Omit<FieldHookConfig<TFieldType>, 'value' | 'onChange'>),
    ) => {
        if ('onChange' in props) {
            return <Component {...(props as TBaseProps)} />;
        } else {
            return ConsumeFormikContext<TFieldType, TBaseProps>(
                props as TBaseProps & FieldHookConfig<TFieldType>,
                Component,
            );
        }
    };
}
