import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { qsParse, qsStringify } from './query-string';
import { filterStringsToEnumValues } from './typescript';

export type Strategy = 'push' | 'replace';
let newQueryParams: {
    [key: string]: string | string[] | null | undefined;
} = {};
let newQueryParamsStrategy: Strategy = 'replace';

function applyNewQueryParams(history: ReturnType<typeof useHistory>) {
    if (Object.keys(newQueryParams).length === 0) {
        return;
    }
    const pathname = history.location.pathname;
    const search = `?${qsStringify({
        ...qsParse(history.location.search),
        ...newQueryParams,
    })}`;
    switch (newQueryParamsStrategy) {
        case 'push':
            history.push({ pathname, search });
            break;
        case 'replace':
            history.replace({ pathname, search });
            break;
    }
    newQueryParams = {};
    newQueryParamsStrategy = 'replace';
}

export function usePersistURLInSessionStorage() {
    const { replace } = useHistory();
    const { pathname: key, search } = useLocation();
    const [isInitialized, setIsInitialized] = useState(false);

    const getValueFromSessionStorage = useCallback(() => {
        // remove trailing slash if it exists to prevent duplicate keys
        const keyValue = key.length && key[key.length - 1] === '/' ? key.slice(0, -1) : key;
        const value = sessionStorage.getItem(keyValue);

        // We only want to use the session value if the inital URL did not have a query string
        // Otherwise we would make bookmarks useless
        if (value && value !== search && !search) {
            return value;
        }
        return undefined;
    }, [key, search]);

    // Update session storage when the search changes
    useEffect(() => {
        const keyValue = key.length && key[key.length - 1] === '/' ? key.slice(0, -1) : key;
        if (!isInitialized) {
            setIsInitialized(true);
            return;
        }
        sessionStorage.setItem(keyValue, search);
    }, [search, key, isInitialized]);

    // Update the URL from session storage when the page loads
    useEffect(() => {
        const existingValue = getValueFromSessionStorage();
        if (existingValue) {
            replace({
                search: existingValue,
            });
        }
    }, [getValueFromSessionStorage, key, replace]);
}

export function useQueryParam(
    key: string,
    defaultValue: string | null = null,
): [string | null, (newValue: string | null, strategy?: Strategy) => void] {
    const history = useHistory();
    const location = useLocation();
    const queryParams = qsParse(location.search);

    const setValue = useCallback(
        (newValue: string | null, strategy: Strategy = 'replace') => {
            newQueryParams[key] = newValue === null ? undefined : newValue;
            if (strategy === 'push') {
                newQueryParamsStrategy = strategy;
            }
            applyNewQueryParams(history);
        },
        [key, history],
    );

    if (queryParams[key] === undefined) {
        return [defaultValue, setValue];
    }

    const rawValue = queryParams[key];
    const value = Array.isArray(rawValue) ? rawValue[0] : rawValue || null;
    return [value, setValue];
}

export function useQueryArrayParam(
    key: string,
    defaultValue: string[] = [],
): [string[], (newValue: string[], strategy?: Strategy) => void] {
    const history = useHistory();
    const location = useLocation();
    const queryParams = qsParse(location.search);
    const setValue = (newValue: string[], strategy: Strategy = 'push') => {
        newQueryParams[key] = newValue;
        if (strategy === 'push') {
            newQueryParamsStrategy = strategy;
        }
        applyNewQueryParams(history);
    };

    if (queryParams[key] === undefined) {
        return [defaultValue, setValue];
    }

    const rawValue = queryParams[key];
    let value: string[];
    if (Array.isArray(rawValue)) {
        value = rawValue;
    } else if (rawValue) {
        value = [rawValue];
    } else {
        value = [];
    }
    return [value, setValue];
}

export function useQueryEnumArrayParam<TEnum extends string>(
    key: string,
    enumType: Record<any, TEnum>,
    defaultValue: string[] = [],
): [TEnum[], (newValue: TEnum[], strategy?: Strategy) => void] {
    const [rawValue, setValue] = useQueryArrayParam(key, defaultValue);
    return [filterStringsToEnumValues(rawValue, enumType), setValue];
}
