import React, { useContext } from 'react';
import { RealSessionDetailsFragment, SessionDetailsFragment, useGetMeQuery } from 'src/graphql/lib';
import { getIntegerId } from 'src/helpers/getIntegerId';
import { AnalyticsContextProvider } from 'src/lib/analytics/context';

interface Value {
    me: SessionDetailsFragment | null;
    realMe: RealSessionDetailsFragment | null;
}

const context = React.createContext<Value | null>(null);

export const SessionProvider: React.FC = ({ children }) => {
    const sessionResult = useGetMeQuery();
    const me = sessionResult.data?.me ?? null;
    const realMe = sessionResult.data?.realMe ?? null;

    const userId = me?.id ? getIntegerId(me.id) : null;
    const attributedUserId = realMe?.id ? getIntegerId(realMe.id) : null;

    if (!sessionResult.called || sessionResult.loading) {
        return null;
    }

    return (
        <context.Provider value={{ me, realMe }}>
            <AnalyticsContextProvider
                value={{ userId: userId, attributedUserId: attributedUserId }}
            >
                {children}
            </AnalyticsContextProvider>
        </context.Provider>
    );
};

export function useSession() {
    const value = useContext(context);
    if (!value) {
        throw new Error('SessionProvider is not detected.');
    }
    return value;
}

export function useNullableSessionUser() {
    const { me } = useSession();
    return me;
}

export function useSessionUser() {
    const { me } = useSession();
    if (!me) {
        throw new Error('Session is not ready.');
    }
    return me;
}

export function useRealSessionUser() {
    const { realMe } = useSession();
    return realMe;
}
