import axios, { AxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN_KEY, IMPERSONATED_KEY } from '../constants/sessionStorage';
import getCookie from './getCookie';

/**
 * Body of a request as a key-value map.
 */
export interface RequestBody {
    [key: string]: any;
}

/**
 * Query-string params of a request as a
 * key-value map.
 */
export interface RequestParams {
    [key: string]: any;
}

/**
 * Request HTTP methods.
 */
export enum HTTPMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
}

/**
 * HTTP headers as a key-value map.
 */
interface HTTPHeaders {
    [key: string]: any;
}

export interface FieldErrors {
    [key: string]: string[] | undefined;
}

export class BadRequestError extends Error {
    fieldErrors: FieldErrors;

    constructor(message: string, fieldErrors: FieldErrors) {
        super(message);
        this.fieldErrors = fieldErrors;
    }
}

export class UnauthorizedError extends Error {
    code: string;

    constructor(message: string, code?: string) {
        const msg = message ?? 'You must be logged in to perform this request.';
        super(msg);
        this.code = code ?? '';
    }
}

export class ForbiddenError extends Error {
    code: string;
    constructor(message: string, code?: string) {
        const msg = message ?? 'You are not authorized to perform this request.';
        super(msg);
        this.code = code ?? '';
    }
}

export class NotFoundError extends Error {
    constructor() {
        super('The requested resource cannot be found.');
    }
}

export class UnprocessableError extends Error {
    constructor(message: string) {
        const msg = message ?? 'The server is unable to process your request.';
        super(msg);
    }
}

export class InternalServerError extends Error {
    constructor() {
        super('Something went wrong.');
    }
}

export interface UploadedFile {
    url: string;
    key: string;
    fileName: string;
    fileType: File['type'];
    fileSize: File['size'];
    lastModified: File['lastModified'];
}

/**
 * ResQ API client that handles URL formatting,
 * auth and errors.
 */
export default class APIClient {
    /** API host URL. */
    protected host: string;
    /** CSRF token cookie name, see https://docs.djangoproject.com/en/2.1/ref/csrf/. */
    protected csrfTokenCookieName: string;

    /**
     * Creates a new instance of the API client.
     * @param host - API host URL.
     * @param csrfTokenCookieName - CSRF token cookie name.
     */
    constructor(host: string, csrfTokenCookieName = 'csrftoken') {
        this.host = host;
        this.csrfTokenCookieName = csrfTokenCookieName;
    }

    /**
     * Makes a GET request.
     * @param path - URL path for the endpoint relative to the host.
     * @param params - Query-string params as a key-value map.
     * @param configs - Optional configs for the request.
     */
    get(path: string, params?: RequestParams) {
        return this.request(HTTPMethod.Get, path, undefined, params);
    }

    /**
     * Makes a POST request.
     * @param path - URL path for the endpoint relative to the host.
     * @param body - Body as a key-value map.
     * @param params - Query-string params as a key-value map.
     * @param configs - Optional configs for the request.
     */
    post(path: string, body?: RequestBody, params?: RequestParams) {
        return this.request(HTTPMethod.Post, path, body, params);
    }

    /**
     * Makes a PUT request.
     * @param path - URL path for the endpoint relative to the host.
     * @param body - Body as a key-value map.
     * @param params - Query-string params as a key-value map.
     * @param configs - Optional configs for the request.
     */
    put(path: string, body?: RequestBody, params?: RequestParams) {
        return this.request(HTTPMethod.Put, path, body, params);
    }

    /**
     * Makes a DELETE request.
     * @param path - URL path for the endpoint relative to the host.
     * @param params - Query-string params as a key-value map.
     * @param configs - Optional configs for the request.
     */
    delete(path: string, params?: RequestParams) {
        return this.request(HTTPMethod.Delete, path, undefined, params);
    }
    async uploadFile(file: File, endpoint?: string): Promise<UploadedFile> {
        const { url, fields, getUrl, relativeKey } = await this.post(
            endpoint ?? '/generate-file-upload-presigned-url/',
            {
                fileName: file.name,
                contentType: file.type,
            },
        );
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
            formData.append(key, fields[key]);
        });
        formData.append('file', file);
        await axios.post(url, formData, {
            headers: { 'Content-Type': file.type },
        });
        file.lastModified;
        return {
            url: getUrl as string,
            key: relativeKey as string,
            fileName: file.name,
            fileType: file.type || 'application/octet-stream',
            fileSize: file.size,
            lastModified: file.lastModified,
        };
    }

    /**
     *
     * NOTE: This is deprecated. Please use `uploadFile` instead.
     *
     * We used to have specific upload endpoints for specific types of files,
     * but we now use a single general endpoint for all file types
     * (`uploadFile`).
     *
     * Uploads a single file to S3 by using generated signed POST.
     * @param url - URL path for generating signed POST to S3
     * @param file - Binary file type that is to be uploaded
     */
    async uploadFileToURL(url: string, file: File | Blob) {
        // extension of that file type of to be uploaded
        const extension = file.type ? file.type.split('/')[1] : undefined;

        // get generated post url and pass extension as param
        const data = await this.post(url, { extension });

        // prepare for uploading to S3
        const headers = {
            'Content-Type': file.type,
        };

        const postData = new FormData();
        for (const key in data.fields) {
            if (data.fields[key]) {
                postData.append(key, data.fields[key]);
            }
        }

        postData.append('file', file);

        const axiosConfig: AxiosRequestConfig = {
            data: postData,
            headers,
            method: HTTPMethod.Post,
            url: data.url,
        };

        try {
            await axios.request(axiosConfig);
        } catch (err) {
            throw new Error('Something went wrong!');
        }

        // return the uploaded url
        return `${data.url}/${data.fields.key}`;
    }

    /**
     * Uploads an array of files to S3 by using generated signed POST.
     * @param url - URL path for generating signed POST to S3
     * @param files - Array of binary files type that is to be uploaded
     */
    async uploadMultipleFilesToURL(url: string, files: Array<File | Blob>) {
        const asyncFileArray = files.map(async (file) => {
            const filePath = await this.uploadFileToURL(url, file);
            return {
                url: filePath,
            };
        });
        const urls = await Promise.all(asyncFileArray);
        return urls;
    }

    /**
     * Makes a HTTP request.
     * @param method - HTTP method.
     * @param path - URL path for the endpoint relative to the host.
     * @param body - Body as a key-value map.
     * @param params - Query-string params as a key-value map.
     * @param configs - Optional configs for the request.
     */
    protected async request(
        method: HTTPMethod,
        path: string,
        body?: RequestBody,
        params?: RequestParams,
    ) {
        // Build URL
        const url = `${this.host}${path}`;

        // Build request headers
        const headers: HTTPHeaders = {
            'Content-Type': 'application/json',
        };
        const csrfToken = getCookie(this.csrfTokenCookieName);
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }
        const impersonated = sessionStorage.getItem(IMPERSONATED_KEY);
        if (impersonated) {
            headers['X-Impersonate'] = impersonated;
        }
        const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }

        // Make request with axios
        try {
            const axiosConfig: AxiosRequestConfig = {
                data: body,
                headers,
                method,
                params,
                url,
                withCredentials: true,
            };
            const response = await axios.request(axiosConfig);
            return response.data;
        } catch (err: any) {
            console.log('error!', err); // do not remove - needed for e2e debugging
            const response = err.response;
            if (!response) {
                throw err;
            }
            // TODO: Implement better error handling
            // Not urgent since we mostly use Apollo
            switch (response.status) {
                case 400:
                    throw new BadRequestError(
                        response.data.error || 'BAD_REQUEST',
                        (response.data || {}).fieldErrors || {},
                    );
                case 401:
                    throw new UnauthorizedError(response.data.detail, response.data.code);
                case 403:
                    throw new ForbiddenError(response.data.detail, response.data.code);
                case 404:
                    throw new NotFoundError();
                case 422:
                    throw new UnprocessableError(response.data.detail);
                default:
                    throw new InternalServerError();
            }
        }
    }
}
