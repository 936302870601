// Function to convert GraphQL Relay ID to database ID
export function getIntegerId(globalId: string) {
    const decoded = atob(globalId);
    return Number(decoded.split(':')[1]);
}

export const isBase64 = (str: string) => {
    if (!str) {
        return false;
    }
    return btoa(atob(str)) === str;
};
