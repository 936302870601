import { AnalyticsFileType } from 'src/lib/analytics/events';

interface FileUrlProps {
    fileLocation: string;
    fileName: string;
    fileType: AnalyticsFileType;
}

export const parseFileUrl = (fileUrl: string): FileUrlProps | null => {
    let url;
    try {
        url = new URL(fileUrl);
    } catch (e) {
        return null;
    }
    const path = url.pathname;
    // File name starts after the last slash
    const nameStart = path.lastIndexOf('/') + 1;
    let fileName = path.substring(nameStart);
    // Extension starts on first dot after name
    const extStart = fileName.indexOf('.') + 1;
    if (extStart === 0) {
        return null;
    }
    // end of extension must be one of: end-of-string or question-mark or hash-mark
    const extEnd = fileName.search(/$|[?#]/);
    fileName = fileName.substring(0, extEnd);
    const ext = fileName.substring(extStart, extEnd).toUpperCase();
    const fileType =
        ext in AnalyticsFileType ? (ext as AnalyticsFileType) : AnalyticsFileType.UNKNOWN;

    return { fileLocation: path, fileName: fileName, fileType: fileType };
};
